import React from "react";
import PGIcon from "./PGIcon";
import MinimizeButton from "./MinimizeButton";
import MaximizeButton from "./MaximizeButton";
import FullScreenButton from "./FullScreenButton";
import CloseButton from "./CloseButton";
import './../pricegen.css';

interface TopBarProps {
  minimized: boolean;
  fullScreen: boolean;
  onMinimize: () => void;
  onFullScreen: () => void;
  customProps: any;
  propData: any;
}

const TopBar: React.FC<TopBarProps> = ({ minimized, fullScreen, onMinimize, onFullScreen, customProps, propData }) => {
  return (
    <div className="title-bar-wrapper">
      <div className="title-bar">
        <div className="title-bar-label">
          <PGIcon />
          <div className="title-bar-label-text-wrapper">
            PriceGen - {propData.storeId} - {propData.description}
          </div>
        </div>
        <div className="title-bar-buttons-wrapper">
          {minimized ? (
            <MaximizeButton onMinimize={onMinimize} />
          ) : (
            <>
              <MinimizeButton onMinimize={onMinimize} />
              {/* <FullScreenButton fullScreen={fullScreen} onFullScreen={onFullScreen} /> */}
            </>
          )}
          <CloseButton customProps={customProps} />
        </div>
      </div>
    </div>
  );
};

export default TopBar;