import { formServiceUrl } from "../util/apiUrlUtil";
import constants from "../util/constants/constants.json";
import apiClient from "../util/apiClient";

export const getAuditTrail = async (priceGenIds: String[]) => {
  try {
    const serviceUrl = await formServiceUrl(
      constants.urlConstants.priceGenPriceViewMaster.name,
      constants.urlConstants.priceGenPriceViewMaster.getAuditTrailStatus
    );

    const response = await apiClient.post<
      {
        [key: string]: {
          priceGenFlowStatus: string;
          priceGenStatus: string;
        };
      }[]
    >(serviceUrl, priceGenIds);
    console.debug(response);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(`HTTP error! status: ${error.response.status}`);
    } else if (error.request) {
      throw new Error(`HTTP error! No response received.`);
    } else {
      throw new Error(`Error: ${error.message}`);
    }
  }
};
