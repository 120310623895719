import React, { useState } from "react";
import "../pricegen.css";

const MaximizeButton = ({ onMinimize }) => {
  return (
    <div className="Maximize-minus-wrapper" style={{ border: '1px solid' }}>
      <svg
        onClick={onMinimize}
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-plus"
        width="28"
        height="28"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="#333333"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        {/*<path stroke="#333333" d="M0 0h24v24H0z" fill="none" />*/}
        <path d="M12 5l0 14" />
        <path d="M5 12l14 0" />
      </svg>
    </div>
  );
};

export default MaximizeButton;
