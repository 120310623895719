import axios from "axios";
import { useState } from "react";
import { constructHeader, formServiceUrl, getEnv } from "../util/apiUrlUtil";
import constants from "../util/constants/constants.json";
const useGetOpisIds = () => {
  const [opisIdsResponse, setOpisIdsResponse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getOpisIds = async (storeIdList) => {
    setLoading(true);
    setError(null);

    try {
      const env = await getEnv();
      const headers = await constructHeader();
      const serviceUrl = await formServiceUrl(
        constants.urlConstants.priceGenPriceViewMaster.name,
        constants.urlConstants.priceGenPriceViewMaster.retrieveOpisIds
      );

      const result = await axios.post(
        serviceUrl,
        {
          storeIdList: storeIdList,
        },
        {
          headers: headers,
        }
      );

      setOpisIdsResponse(result.data);
      return result.data;
    } catch (err) {
      setError(
        err.response?.data || { message: "An error fetching OPIS IDs Occured" }
      );
    } finally {
      setLoading(false);
    }
  };

  return { opisIdsResponse, loading, error, getOpisIds };
};

export default useGetOpisIds;
