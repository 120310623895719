import React from 'react';

interface DataCellProps {
  data: any; 
}

const DataCell: React.FC<DataCellProps> = ({ data }) => {
  const renderContent = (data: any) => {
    if (typeof data === 'string') {
      return data.split('|').map((line, index) => {
        const [firstWord, ...rest] = line.trim().split(':');
        return (
          <div key={index}>
            <strong>{firstWord}:</strong>{rest.join(':')}
          </div>
        );
      });
    }

    if (typeof data === 'object' && data !== null) {
      return (
        <div>
          {Object.entries(data).map(([key, value], index) => (
            <div key={index}>
              <strong>{key}:</strong> {renderContent(value)}
            </div>
          ))}
        </div>
      );
    }

    return String(data);
  };

  return <div>{renderContent(data)}</div>;
};

export default DataCell;