import React, { useContext, useEffect, useState } from "react";
import "./PriceReview.css";
import PriceInput from "./PriceInput/PriceInput";
import {
  calculateDynamicOffset,
  calculateHorizontalDistanceInEms,
  formatDateToString,
  getImagePath,
  iconList,
} from "../../util/util";
import ActionBar from "./ActionBar";
import { PriceDataContext } from "../../context/PriceDataContext";
import PriceDataModel from "../../models/priceView/PriceData.model";
import CircularProgress from "@mui/material/CircularProgress";
import { ActionButtonTd } from "./ActionButtonTd";
import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { PRICEVIEW_HEADER_COLUMNS } from "../../util/constants";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

interface PriceReviewProps {}

const PriceReview: React.FC<PriceReviewProps> = () => {
  const priceDataCtx = useContext(PriceDataContext);
  // use windowSize state to ultimately align the all up/down with the individual price input
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const {
    priceData,
    changeProposedPriceStatusForAll,
    setSelectedPriceData,
    setSelectedPriceDataIndex,
    selectedPriceData,
  } = priceDataCtx;

  const priceInputRef = React.useRef(null);
  const tableRef = React.useRef(null);

  const [inputDistance, setInputDistance] = React.useState(0);
  const [_, setDynamicOffset] = React.useState(0);

  useEffect(() => {
    if (priceInputRef.current && tableRef.current) {
      const inputLeft = priceInputRef.current.getBoundingClientRect().left;
      const tableLeft = tableRef.current.getBoundingClientRect().left;
      const distanceInEms = calculateHorizontalDistanceInEms(
        inputLeft,
        tableLeft
      );
      setInputDistance(distanceInEms);
    }
  }, [priceData, windowSize]);

  useEffect(() => {
    const handleResize = () => {
      const viewportWidth = window.innerWidth;
      setDynamicOffset(calculateDynamicOffset(viewportWidth));
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function getReasonIcon(pd: PriceDataModel): string {
    let iconPath: string;
    switch (pd.priceData.latestPriceGenPriceStatus) {
      case "PENDING":
        iconPath = iconList.info;
        break;
      case "APPROVED":
        iconPath = iconList.infoApproved;
        break;
      case "REJECTED":
        iconPath = iconList.infoRejected;
        break;
      case "EXPORTED":
        iconPath = iconList.infoExported;
        break;
      default:
        return iconList.info;
    }
    return iconPath;
  }

  function anyValidationsFailed(validations) {
    if (!!validations) {
      for (let i = 0; i < validations.length; i++) {
        if (validations[i].validationFailed) {
          return true;
        }
      }
    }
    return false;
  }

  function getCompetitorBoundaryValidationDetails(
    competitorBoundaryFailedOpisId
  ) {
    return competitorBoundaryFailedOpisId.map((boundary) => {
      let message = "Competitor: " + boundary.opisId;

      if (
        boundary.derivedLowerPrice != null &&
        boundary.generatingPrice < boundary.derivedLowerPrice
      ) {
        message += ` - Price fell below minimum boundary (${boundary.derivedLowerPrice})`;
      }

      if (
        boundary.derivedUpperPrice != null &&
        boundary.generatingPrice > boundary.derivedUpperPrice
      ) {
        message += ` - Price fell above maximum boundary (${boundary.derivedUpperPrice})`;
      }

      return <li key={boundary.opisId}>{message}</li>;
    });
  }

  function generateTableBody() {
    return (
      <>
        {priceData.map((dataItem: PriceDataModel, index: number) => (
          <tr
            className={
              dataItem.displayName === selectedPriceData?.displayName
                ? "selected"
                : ""
            }
            key={dataItem.productId || index}
            onClick={() => {
              setSelectedPriceData(dataItem);
              setSelectedPriceDataIndex(index);
            }}
          >
            <ActionButtonTd
              dataItem={dataItem}
              mode={"approve"}
            ></ActionButtonTd>
            <ActionButtonTd dataItem={dataItem} mode={"deny"}></ActionButtonTd>
            <td className={`bordered-cell`}>
              <HtmlTooltip
                title={
                  <>
                    <div>Current Status: {dataItem.description.status}</div>
                    <div>
                      Original Price: {dataItem.description.originalPrice}
                    </div>
                    <div>Comments: {dataItem.description.statusComments}</div>
                    {dataItem?.createdBy && dataItem.createdBy == 'MARKETMOVE' ? <div>Active Market Move</div> : <div></div> }
                    <div>Validations:</div>
                    {dataItem?.validations?.map((validation: any) => (
                      <div key={validation.validationType}>
                        {validation.validationFailed ? (
                          <CloseIcon sx={{ color: "red" }} />
                        ) : (
                          <CheckIcon sx={{ color: "green" }} />
                        )}{" "}
                        {validation.validationName}
                        {validation.validationType == "CompetitorBoundary" &&
                        validation.validationFailed ? (
                          <ul>
                            {" "}
                            {getCompetitorBoundaryValidationDetails(
                              validation.competitorBoundaryFailedOpisId
                            )}
                          </ul>
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                  </>
                }
              >
                <span
                  className={`reason ${
                    anyValidationsFailed(dataItem.validations)
                      ? "validation-failed"
                      : ""
                  }`}
                >
                  <img src={getReasonIcon(dataItem)} alt="Reason" />
                </span>
              </HtmlTooltip>
            </td>
            <td
              style={{
                backgroundColor: dataItem.backgroundColor,
                color: dataItem.textColor,
              }}
            >
              <span>{dataItem.displayName}</span>
            </td>

            <td ref={priceInputRef}>
              <PriceInput productId={dataItem.productId} />
            </td>
            <td>
              {formatDateToString(
                dataItem.priceData.latestPriceGenPriceEffectiveDate
              )}
            </td>
            <td>
              {dataItem.priceData.txnPrice
                ? `${dataItem.priceData.txnPrice}`
                : ""}
            </td>
            <td>
              {formatDateToString(dataItem.priceData.txnPriceEffectiveDate)}
            </td>
            <HtmlTooltip
              title={`Exported date: ${formatDateToString(
                dataItem.priceData.lastExportedPriceEffectiveDate
              )}`}
            >
              <td>{dataItem.priceData.lastExportedProposedPrice || 0}</td>
            </HtmlTooltip>
            <td>{getPriceMove(dataItem)}</td>
            <td>{dataItem.priceAge}</td>
            <HtmlTooltip title={dataItem.priceData.strategy}>
              <td>{dataItem.strategy}</td>
            </HtmlTooltip>
            <td>
              {dataItem.priceData.latestPriceGenModifiedBy ||
                dataItem.priceData.createdBy}
            </td>
          </tr>
        ))}
      </>
    );
  }

  if (priceData.length === 0) {
    return (
      <div className={"price-review spinner"}>
        <CircularProgress disableShrink />
      </div>
    );
  }

  return (
    <div className="price-review">
      <table ref={tableRef}>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            {PRICEVIEW_HEADER_COLUMNS.map(({ text, tooltip }) => (
              <th key={text}>
                {tooltip ? (
                  <HtmlTooltip title={tooltip}>
                    <span dangerouslySetInnerHTML={{ __html: text }} />
                  </HtmlTooltip>
                ) : (
                  <span dangerouslySetInnerHTML={{ __html: text }} />
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {/* Approve/Reject ALL Buttons */}
          <tr>
            <td className="bordered-cell status-approved">
              <button
                className={"action-btn approve-all"}
                onClick={() => changeProposedPriceStatusForAll("APPROVED")}
                disabled={priceData.every((item) => item.disabled)}
              >
                <img src={getImagePath("checkmarkGreen")} alt={"Approve All"} />
              </button>
            </td>
            <td className="bordered-cell status-rejected">
              <button
                className={"action-btn deny-all"}
                onClick={() => changeProposedPriceStatusForAll("REJECTED")}
                disabled={priceData.every((item) => item.disabled)}
              >
                <img src={getImagePath("denyRed")} alt={"Deny All"} />
              </button>
            </td>
          </tr>
          {generateTableBody()}
        </tbody>
      </table>
      <ActionBar inputDistance={inputDistance} />
    </div>
  );
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className, arrow: "true" }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(14),
    border: "1px solid #dadde9",
  },
}));

function getPriceMove(pd: PriceDataModel) {
  // returns the number in green if it's positive, red if it's negative
  // and black if the same as html
  const green = "rgb(28, 160, 112)";
  const red = "rgb(243, 78, 31)";
  const priceDiffInCents =
    pd.proposedPrice - pd.priceData.lastExportedProposedPrice;
  let priceDiffColor = "";

  if (priceDiffInCents > 0) {
    priceDiffColor = green;
  } else if (priceDiffInCents < 0) {
    priceDiffColor = red;
  }
  return (
    <span style={{ color: priceDiffColor }}>{priceDiffInCents.toFixed(2)}</span>
  );
}

export default PriceReview;
