/* eslint-disable no-console */
import React, { useContext, useEffect, useState, useRef } from "react";
import "./Strategy.css";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import Button from "@mui/material/Button";
import { GetStrategyParams, useGetStrategy } from "../../../api/getStrategyApi";
import { ThemeProvider } from "@mui/material/styles";
import RuleLinkToOwnProductSettings from "./RuleLinkToOwnProductSettings";
import {
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import MenuItem from "@mui/material/MenuItem";
import RuleLowestCompetitorWithOffsetSettings from "./RuleLowestCompetitorWithOffsetSettings";
import ControlsAndValidations from "../ControlsAndValidations/ControlsAndValidations";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { CompetitorPriceDataContext } from "../../../context/CompetitorPriceDataContext";
import { getUserName } from "../../../util/util";
import { useSaveStrategy } from "../../../api/saveStrategyApi";
import { useSaveValidation } from "../../../api/saveValidationApi";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import zodSchema from "./StrategyFormZodSchema";
import strategyTheme from "./StrategyTheme";
import StrategyFormDataErrorList from "./StrategyFormDataErrorList";
import {Backdrop, CircularProgress, IconButton, Tooltip} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const StrategyEditor = ({ storeId, productId }) => {
  const { initialData } = useContext(CompetitorPriceDataContext);

  const strategyScrollAreaRef = useRef(null); // used to set the backdrop height because it is absolutely positioned and can't handle the scroll area itself.

  const methods = useForm({
    // Initialize defaults... these will get set properly when the API loads.
    defaultValues: {
      ruleType: null,
      autoApprovalFlag: false,
      linkedProduct: "",
      linkedProductSpread: 0,
      competitorOffsets: [],
    },
    resolver: zodResolver(zodSchema),
  });

  const {
    watch,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { dirtyFields, isValid, isDirty, errors },
  } = methods;

  const getBaseRuleType = () => {
    if(ruleType === "RuleLinkToOwnProduct") {
      return "linked";
    }
    return "compOffset";
  }

  const getAggregationMethod = () => {
    switch(ruleType) {
      case "RuleLowestCompetitorWithOffset":
        return "lowest"
      case "RuleSecondLowestCompetitorWithOffset":
        return "secondLowest"
      case "RuleAverageCompetitorWithOffset":
        return "average"
    }
    return "lowest";
  }

  const [snackVisible, setSnackVisible] = useState(false);
  const [saveStrategyErrorMsg, setSaveStrategyErrorMsg] = useState("");
  const [saveValidationErrorMsg, setSaveValidationErrorMsg] = useState("");
  const [baseRule, setBaseRule] = useState(getBaseRuleType());
  const [aggregationMethod, setAggregationMethod] = useState(getAggregationMethod());
  type StrategyFormData = z.infer<typeof zodSchema>;

  const ruleType = watch("ruleType");

  // API calls, hooks and such
  const {
    data: strategy,
    isFetching: isStrategyLoading,
    refetch,
  } = useGetStrategy({ storeId, productId } as GetStrategyParams);

  useEffect(() => {
    setBaseRule(getBaseRuleType());
    setAggregationMethod(getAggregationMethod());
  }, [ruleType]);

  const {
    mutate: strategyMutation,
    isError: isSaveStrategyError,
    isLoading: isSaveStrategyLoading,
  } = useSaveStrategy();

  const {
    mutate: validationMutation,
    isError: isSaveValidationError,
    isLoading: isSaveValidationLoading,
  } = useSaveValidation();

  useEffect(() => {
    // Handle new strategy data load.
    if (!isStrategyLoading) {
      let rule = null;
      if (strategy?.rules?.length > 0) {
        rule = strategy?.rules[0];
      }

      if (rule?.type != undefined) {
        setValue("ruleType", rule?.type);
      }

      let defaultValues: any = control._defaultValues;
      defaultValues.ruleType = rule?.type;
      defaultValues.linkedProduct =
        rule?.type == "RuleLinkToOwnProduct" ? rule?.linkedProduct : null;
      defaultValues.linkedProductSpread =
        rule?.type == "RuleLinkToOwnProduct" ? rule?.spread / 100 : null;
      //defaultValues.competitorOffsets = strategy?.competitorOffsets;

      let competitors = [];
      for (let i = 0; i < initialData?.length; i++) {
        competitors.push({
          competitorId: initialData[i].opisId,
          offset: getCompetitorByOpisID(
            initialData[i].opisId,
            strategy?.competitorOffsets
          )?.offset,
          isSelected: strategy?.competitorOffsets.some(
            ({ competitorId }) => competitorId == initialData[i].opisId
          ),
        });
      }

      defaultValues.competitorOffsets = competitors;

      // A race condition can happen where the competitor grid loads before the validations, but after the strategy load.
      // We need to create some dummy data to get indexes in the competitorBoundary array, or we end up binding to null
      // indexes and data doesn't load into the controls once validations do load.
      if (!("CompetitorBoundary" in defaultValues)) {
        console.log("Inserting CompetitorBoundary placeholder...");
        defaultValues.CompetitorBoundary = {
          validationData: {
            competitorBoundary: [],
          },
        };

        // Fill the dummy array with initial items for every competitor coming from the competitor grid tab's data context.
        initialData
          ?.sort((a, b) =>
            a.opisId > b.opisId ? 1 : b.opisId > a.opisId ? -1 : 0
          )
          .map((item) => {
            console.log("Adding dummy record for opisId: " + item.opisId);
            defaultValues?.CompetitorBoundary?.validationData?.competitorBoundary.push(
              {
                opisId: item.opisId,
              }
            );
          });
      }

      reset({ ...defaultValues });
    }
  }, [isStrategyLoading, storeId, productId, initialData]);

  useEffect(() => {
    console.log("Store/Product changed.  Refetching strategy.");
    if (!isStrategyLoading) {
      // Don't refetch if we're already doing it
      refetch();
    }
  }, [storeId, productId]);

  // Transform and submit form data
  function addRemovedCompetitors(
    originalCompetitors,
    competitorOffsets: any[]
  ) {
    originalCompetitors?.forEach((oc) => {
      if (!competitorOffsets.some((co) => co.competitorId == oc.competitorId)) {
        competitorOffsets.push({
          competitorId: oc.competitorId,
          offset: oc.offset,
          action: "REMOVE",
        });
      }
    });
  }
  function getSelectedCompetitorsFromFormData(formData, originalCompetitors) {
    // Load Selected Competitors
    let competitorOffsets = [];
    for (let i = 0; i < formData.competitorOffsets?.length; i++) {
      const comp = formData.competitorOffsets[i];

      if (comp?.isSelected) {
        let action = "ADD"; // Default action

        // Find original competitor from the strategy loaded from the API
        const originalCompetitor = originalCompetitors?.find(
          (oc) => oc.competitorId == comp.competitorId
        );

        // If we found a match, set this to UPDATE if we changed the offset.  If nothing changed, action is NONE.
        if (originalCompetitor) {
          action =
            originalCompetitor.offset?.toString() == comp.offset?.toString()
              ? "NONE"
              : "UPDATE";
        }

        let competitor = {
          competitorId: comp.competitorId,
          offset: comp.offset,
        };

        // add action only when it is not NONE
        if (action !== "NONE") {
          competitor["action"] = action;
        } else {
          competitor["action"] = null;
        }

        competitorOffsets.push(competitor);
      }
    }
    addRemovedCompetitors(originalCompetitors, competitorOffsets);

    console.log(
      `Building competitor list: \nOriginals: ${JSON.stringify(
        originalCompetitors
      )}\nformData: ${JSON.stringify(
        formData?.competitorOffsets
      )}\nRESULT: ${JSON.stringify(competitorOffsets)}`
    );

    return competitorOffsets;
  }
  function GetStrategyRulesFromFormData(formData) {
    let rules;
    switch (formData.ruleType) {
      case "RuleLinkToOwnProduct":
        rules = [
          {
            type: formData.ruleType,
            linkedProduct: formData.linkedProduct,
            spread:
              formData.linkedProductSpread == null
                ? 0
                : formData.linkedProductSpread * 100, // Screen displays as $ but internally stored as cents.
          },
        ];
        break;
      default:
        rules = [
          {
            type: formData.ruleType,
          },
        ];
        break;
    }
    return rules;
  }
  const convertStrategyFormDataForAPI = (formData) => {
    const mapping = {
      storeId: storeId,
      productId: productId,
    };
    let rules = GetStrategyRulesFromFormData(formData);
    let originalCompetitors = strategy?.competitorOffsets;

    let competitorOffsets = getSelectedCompetitorsFromFormData(
      formData,
      originalCompetitors
    );

    let result: {};

    result = {
      mapping: mapping,
      rules: rules,
      competitorOffsets: competitorOffsets,
      lastUpdatedBy: getUserName(),
    };

    if (strategy?.id != null) {
      result = {
        id: strategy.id,
        ...result,
      };
    }

    return result;
  };

  function setEnabledFlagFromCompBoundary(formData) {
    let compBoundary = formData?.CompetitorBoundary;
    console.debug("BOUNDARY ENABLED FLAG CHECK");
    compBoundary.enabled.flag = false;
    for (
      let i = 0;
      i < compBoundary?.validationData?.competitorBoundary?.length;
      i++
    ) {
      // console.debug(
      //   `compBoundary?.validationData?.competitorBoundary[${i}].lowerThreshold = ${compBoundary?.validationData?.competitorBoundary[i].lowerThreshold}`
      // );
      // console.debug(
      //   `compBoundary?.validationData?.competitorBoundary[${i}].upperThreshold = ${compBoundary?.validationData?.competitorBoundary[i].upperThreshold}`
      // );
      if (
        compBoundary?.validationData?.competitorBoundary[i].lowerThreshold !=
          null ||
        compBoundary?.validationData?.competitorBoundary[i].upperThreshold !=
          null
      ) {
        console.debug("ENABLED THE FLAG");
        compBoundary.enabled.flag = true;
      }
    }

    return compBoundary;
  }

  function removeCompetitorsWhichAreNoLongerPartOfCompetitorGrid(compBoundary) {
    const compBoundaryList = compBoundary.validationData.competitorBoundary;
    for (let i = compBoundaryList?.length - 1; i >= 0; i--) {
      const boundary = compBoundary.validationData.competitorBoundary[i];
      const bExistsOnCompetitorGrid = initialData.some(
        (b) => b?.opisId == boundary?.opisId
      );
      if (!bExistsOnCompetitorGrid) {
        removeCompetitorByOpisId(compBoundary, boundary?.opisId);
      }
    }
  }

  function removeCompetitorByOpisId(compBoundary, opisId) {
    console.log(
      `Removing competitor ${opisId} because it is no longer part of comp grid.`
    );

    let compBoundaryList = compBoundary.validationData.competitorBoundary;

    for (let i = 0; i < compBoundaryList?.length; i++) {
      const boundary = compBoundary.validationData.competitorBoundary[i];
      if (boundary.opisId == opisId) {
        compBoundary.validationData.competitorBoundary.splice(i, 1);
        break;
      }
    }
  }

  const convertControlsAndValidationsDataForAPI = (formData) => {
    const validationList = [];

    let compBoundary = setEnabledFlagFromCompBoundary(formData);
    removeCompetitorsWhichAreNoLongerPartOfCompetitorGrid(compBoundary);

    validationList.push(formData.lastDigitNine);
    validationList.push(formData.PriceFallUnderMinMax);
    validationList.push(formData.MaxIncreaseDecrease);
    validationList.push(formData.priceMustChange);
    validationList.push(compBoundary);
    validationList.push(formData.OpisAge);

    return {
      id: formData.validationId,
      storeId: storeId,
      productId: productId,
      defaultProfile: formData.defaultProfile,
      lastUpdatedBy: getUserName(),
      autoApprove: {
        flag: formData.autoApprovalFlag,
        schedule: {},
      },
      validationList: validationList,
    };
  };

  const onSubmit: SubmitHandler<StrategyFormData> = (data) => {
    console.log("--------------- ON SUBMIT -------------------");

    console.log(data);

    const strategySaveRequest = convertStrategyFormDataForAPI(data);
    const validationSaveRequest = convertControlsAndValidationsDataForAPI(data);

    // Save strategy
    strategyMutation(strategySaveRequest, {
      onError: (error) => {
        setSaveStrategyErrorMsg(`Saving strategy failed!`);
        console.error(JSON.stringify(error));
        setSnackVisible(true);
      },
      onSuccess: () => {
        // Save validations
        validationMutation(validationSaveRequest, {
          onError: (error) => {
            setSaveValidationErrorMsg(`Saving validation failed!`);
            console.error(JSON.stringify(error));
            setSnackVisible(true);
          },
          onSuccess: () => {
            setSnackVisible(true);
          },
        });
      },
    });

    console.log("------------- END ON SUBMIT -----------------");
  };

  // Watches
  const selectedRuleType = watch("ruleType");

  // Helpers / Utility
  const getCompetitorByOpisID = (opisId, competitorData) => {
    for (let i = 0; i < competitorData?.length; i++) {
      if (opisId == competitorData[i].competitorId) {
        return competitorData[i];
      }
    }
    return null;
  };

  const handleOnChangeAggregationMethod = (event: SelectChangeEvent) => {
    setAggregationMethod(event.target.value as string);
    setValue("ruleType", getSelectedRule(baseRule, event.target.value),{
      shouldValidate: true,
      shouldDirty: true
    });
  }

  const handleOnChangeBaseRule = (event: SelectChangeEvent) => {
    setBaseRule(event.target.value as string);
    setValue("ruleType", getSelectedRule(event.target.value, aggregationMethod),{
      shouldValidate: true,
      shouldDirty: true
    });
  }

  const getSelectedRule = (base, agg) => {
    if(base == "compOffset") {
      switch(agg) {
        case "lowest":
          return "RuleLowestCompetitorWithOffset";
        case "secondLowest":
          return "RuleSecondLowestCompetitorWithOffset";
        case "average":
          return "RuleAverageCompetitorWithOffset"
      }
    }
    return "RuleLinkToOwnProduct";
  }

  return (
    <div className={`strategy-container`}>
      <ThemeProvider theme={strategyTheme}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="strategy-container-inner">
              <Backdrop
                sx={{
                  color: "#fff",
                  position: "absolute",
                  height:
                    strategyScrollAreaRef?.current?.getBoundingClientRect()
                      .height + 110, // Adding 110 to account for the float, which doesn't contribute to the height.
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={
                  isStrategyLoading ||
                  isSaveStrategyLoading ||
                  isSaveValidationLoading
                }
              >
                {isSaveStrategyLoading || isSaveValidationLoading
                  ? "Saving..."
                  : "Loading..."}{" "}
                <CircularProgress color="inherit" />
              </Backdrop>

              <div className="strategy-config" ref={strategyScrollAreaRef}>
                <div className={`rules-configuration`}>
                  <div className="rule-container">
                    <div className={"div-wrapper"}>
                      <div className={"section-label"}>Rule</div>
                    </div>
                    <div className={`rule-dropdown-container rule-dropdown`}>
                      <Select
                          value={baseRule}
                          onChange={handleOnChangeBaseRule}
                          displayEmpty>
                        <MenuItem value="compOffset">Comp(s) Offset(s)</MenuItem>
                        <MenuItem value="linked">Linked</MenuItem>
                      </Select>
                    </div>
                  </div>

                  {
                    baseRule == "compOffset" &&
                    <div className="rule-container">
                      <div className={"div-wrapper"}>
                        <div className={"section-label"}>Rule Behavior</div> <Tooltip
                          title="Set the behavior that determines which competitor prices are followed."
                      >
                        <IconButton className={"RuleBehaviorInfoButton"}>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                      </div>
                      <div className={`rule-dropdown-container rule-dropdown`}>
                        <Select
                            value={aggregationMethod}
                            onChange={handleOnChangeAggregationMethod}
                            displayEmpty>
                          <MenuItem value="lowest">Lowest</MenuItem>
                          <MenuItem value="secondLowest">Second Lowest</MenuItem>
                          <MenuItem value="average">Average</MenuItem>
                        </Select>
                      </div>
                    </div>
                  }

                  <RuleLowestCompetitorWithOffsetSettings
                    rule={getSelectedRule(baseRule, aggregationMethod)}
                    strategy={strategy}
                  />

                  <RuleLinkToOwnProductSettings
                    rule={selectedRuleType}
                    strategy={strategy}
                  />
                  <hr />

                  <ControlsAndValidations
                    storeId={storeId}
                    productId={productId}
                    selectedRuleType={selectedRuleType}
                  />

                  <StrategyFormDataErrorList />

                  <div className={"rule-dropdown-container submit-container"}>
                    <Button
                      variant="contained"
                      disabled={Object.keys(errors)?.length > 0 || !isDirty}
                      type="submit"
                    >
                      Submit
                    </Button>
                    <Snackbar
                      open={snackVisible}
                      autoHideDuration={
                        isSaveStrategyError || isSaveValidationError
                          ? 12000
                          : 4000
                      }
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      onClose={() => setSnackVisible(false)}
                    >
                      <Alert
                        severity={
                          isSaveStrategyError || isSaveValidationError
                            ? "error"
                            : "success"
                        }
                        variant="filled"
                        sx={{
                          width: "100%",
                          maxWidth: "50vh",
                          marginRight: "50px",
                        }}
                      >
                        {isSaveStrategyError || isSaveValidationError ? (
                          <div>
                            Save was not successful!
                            <br />
                            {saveStrategyErrorMsg != "" &&
                              JSON.stringify(saveStrategyErrorMsg)}
                            {saveValidationErrorMsg != "" &&
                              JSON.stringify(saveValidationErrorMsg)}
                          </div>
                        ) : (
                          <div>Save successful</div>
                        )}
                      </Alert>
                    </Snackbar>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </ThemeProvider>
    </div>
  );
};

export default StrategyEditor;
