import Box from '@mui/material/Box';
import React from 'react';
import './pricegen.css';

interface DisabledViewProps {
    message: string;
  }
  
  const DisabledView: React.FC<DisabledViewProps> = ({ message }) => (

      <div className="pricegen disabled-text">
        <span dangerouslySetInnerHTML={{ __html: "&#x2639;" }} /> {message}
      </div>
    
  );

  export default DisabledView;