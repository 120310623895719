import React, { useEffect, useState, useCallback, useRef, useContext } from "react";
import TopBar from "./TopBar/TopBar";
import "./pricegen.css";
import TabContainer from "./TabContainer/TabContainer";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import PriceReview from "./PriceReview/PriceReview";
import { PriceDataProvider } from "../context/PriceDataContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import useActiveMediaQuery from "../hooks/useActiveMediaQuery";
import { CompetitorPriceDataProvider } from "../context/CompetitorPriceDataContext";
import { fetchProductMapping } from "../api/mongoEndpoints";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import tableTheme from "../theme/TableTheme";
import { Resizable } from "re-resizable";
import "react-resizable/css/styles.css";
import Draggable from "react-draggable";
import DisabledView from "./DisabledView";
import { Menu, MenuItem } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { CompetitorPriceDataContext } from "../context/CompetitorPriceDataContext";

interface CustomPropsData {
  rowSelectionType: 'Single' | 'Multiple' | 'None';
  coords?: {
    lat: number;
    lon: number;
  };
  description?: string;
  storeId?: string;
  fpisId?: string;
  priceSource?: string;
}

const PriceGenContainer: React.FC<{ customProps: any }> = ({ customProps }) => {
  const [minimized, setMinimized] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [height, setHeight] = useState<number | "auto">("auto");
  const nodeRef = useRef(null);
  const [propData, setPropData] = useState<CustomPropsData>({rowSelectionType: 'None'});
  // const { maxBoxHeight, minBoxWidth, fontSize } = useActiveMediaQuery();
  const [bounds, setBounds] = useState({ left: 0, top: 0, right: 0, bottom: 0 });
  const [queryClient] = useState(new QueryClient());  // Only initialize this on the first render to prevent destroying query state.
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { initialData } = useContext(CompetitorPriceDataContext);
  const [resetKey, setResetKey] = useState(0);
  const [size, setSize] = useState({ width: '60vw', height: "auto" });

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);


  //set refresh initiator for when the tab swaps 
  useEffect(() => {
    let isComponentMounted = true;
  
    const updateBounds = () => {
      if (!isComponentMounted) return;
  
      const mapWrapper = document.querySelector('.gm-style');
      const container = document.querySelector('.resizable-container');
      if (!mapWrapper || !container) return;
  
      const topbarHeight = window.innerHeight > 1080 ? 135 : 60;
      const mapHeight = mapWrapper.clientHeight;
      const containerHeight = height === "auto" 
        ? container.clientHeight 
        : height;
  
      setBounds({
        left: -(mapWrapper.clientWidth), 
        top: -(mapHeight - container.clientHeight),
        right: 0,
        bottom: 0
      });
    };
  
    let timeout: number | null = null;
    const debouncedUpdate = () => {
      if (timeout) window.clearTimeout(timeout);
      timeout = window.setTimeout(updateBounds, 100);
    };
  
    window.addEventListener('resize', debouncedUpdate);
    
    setTimeout(updateBounds, 500);
  
    return () => {
      isComponentMounted = false;
      if (timeout) clearTimeout(timeout);
      window.removeEventListener('resize', debouncedUpdate);
    };
  }, [height, initialData]);

  const onMinimize = useCallback(() => {
    setMinimized((prev) => !prev);
  }, []);

  const onFullScreen = useCallback(() => {
    setFullScreen((prev) => {
      if (!prev) {
        setPosition(prevPosition => ({ ...prevPosition, y: 0 })); 
        setHeight(window.innerHeight - 60);
      }
      else{
        setHeight("auto");
      }
      return !prev;
    });
  }, []);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : null,
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleResetPosition = () => {
    setPosition({ x: 0, y: 0 });
    setHeight("auto");
    setSize({ width: `60vw`, height: "auto" });
    handleClose();
  };

  // const onResize = useCallback((event, { size }) => {
  //   setHeight(size.height);
  // }, []);

  const handleDrag = (e, data) => {
    setPosition({ x: data.x, y: data.y });
  };

  useEffect(() => {
    const subscription = customProps?.compRef?.mapLayerService?.openPriceGenWindow$?.subscribe(
      (data: CustomPropsData) => {
        setPropData(data);
      }
    );

    fetchProductMapping().then();

    return () => {
      subscription?.unsubscribe();
    };
  }, [customProps]);

  const getContentMessage = (): string => {
    console.log("LOOK PROPDATA:", propData);
    if (!propData.rowSelectionType || propData.rowSelectionType === 'None') {
      return "No stores selected, Please select a store";
    }
    if (propData.rowSelectionType === 'Multiple') {
      return "Multiple stores are selected, please select single store";
    }
    if (propData.rowSelectionType === 'Single' && propData.priceSource !== 'PriceGen') {
      return "Selected Store is not available in PriceGen";
    }
    return "";
  };

  const shouldShowDisabledView = (): boolean => {
    if (!propData.rowSelectionType || propData.rowSelectionType === 'None') return true;
    if (propData.rowSelectionType === 'Multiple') return true;
    if (propData.rowSelectionType === 'Single' && propData.priceSource !== 'PriceGen') return true;
    return false;
  };

  const renderMainContent = () => (
    <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1, width: "60vw", height: "auto" }}>
      <PriceDataProvider seiId={propData.storeId} fpisStoreId={propData.fpisId}>
        <CompetitorPriceDataProvider coords={propData.coords}>
          <Box sx={{ flexGrow: 1 }}>
            <PriceReview />
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <ThemeProvider theme={tableTheme()}>
              <TabContainer propData={propData} />
            </ThemeProvider>
          </Box>
        </CompetitorPriceDataProvider>
      </PriceDataProvider>
    </Box>
  );

  return (
    <div className="pricegen" onContextMenu={handleContextMenu}>
    <QueryClientProvider client={queryClient}>
      <Draggable
        nodeRef={nodeRef}
        handle=".title-bar"
        position={position}
        onDrag={handleDrag}
        bounds={bounds}
        grid={[1, 1]}
      >
        <div ref={nodeRef} className="idkanymore">
        <Resizable
          className={`resizable-container pricegen-wrapper ${minimized ? "minimized" : ""}`}
          size={size}
          defaultSize={{
            width: `60vw`,
            height: "auto",
          }}
          onResize={(e, direction, ref, d) => {
            setSize({ width: `60vw`, height: ref.style.height });
          }}
          maxHeight={height === "auto" ? window.innerHeight - 60 : height}
          enable={{
            top: false,
            right: false,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            width: '60vw',
            height: minimized ? 'auto' : height,
            maxHeight: window.innerHeight-40,
            fontSize: `14px`,
            position: 'relative',
          }}
        >
          <TopBar
            propData={propData}
            minimized={minimized}
            onMinimize={onMinimize}
            fullScreen={fullScreen}
            onFullScreen={onFullScreen}
            customProps={customProps}
          />
          {!minimized && (
            shouldShowDisabledView() 
              ? <DisabledView message={getContentMessage()} />
              : renderMainContent()
          )}
        </Resizable>
        </div>
      </Draggable>
      <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem onClick={handleResetPosition}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <RestartAltIcon sx={{ mr: 1 }} />
              Reset Position
            </Box>
          </MenuItem>
        </Menu>
    </QueryClientProvider>
    </div>
  );
};
export default PriceGenContainer;
