export const PRICEVIEW_HEADER_COLUMNS = [
  {
    text: "Product",
    tooltip: null
  },
  {
    text: "Proposed <br/> Price",
    tooltip: null
  },
  {
    text: "Proposed Price <br /> Effective Date",
    tooltip: null
  },
  {
    text: "TXN Price",
    tooltip: "The last successful transaction price received from the store"
  },
  {
    text: "TXN Date",
    tooltip: "The date of the last successful transaction price received from the store"
  },
  {
    text: "Last Exported <br/> Price",
    tooltip: "The last price that was exported to the store. Hover over the price to see the date it was exported."
  },
  {
    text: "Proposed <br/> Price Move",
    tooltip: "The difference between the proposed price and the exported price"
  },
  {
    text: "Price Age <br/> (Days)",
    tooltip: "The number of days since the last successful export"
  },
  {
    text: "Strategy",
    tooltip: null
  },
  {
    text: "Created <br /> by",
    tooltip: null
  },
];
