import React, { useState } from "react";
import "../pricegen.css";

const MinimizeButton = ({ onMinimize }) => {
  return (
    <div className="minimize-minus-wrapper" style={{ border: '1px solid' }}>
      <svg
        onClick={onMinimize}
        className={`size-28`}
        fill="none"
        height="28" // Actual size is 15px. SVG seems to include some padding.
        viewBox="0 0 32 32"
        width="28"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="path"
          d="M6.66669 16H25.3334"
          stroke="#333333"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </svg>
    </div>
  );
};

export default MinimizeButton;
