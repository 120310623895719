import React, { useContext, useMemo } from "react";
import { MaterialReactTable } from "material-react-table";
import "./CompetitorPrices.css";
import { CompetitorPriceDataContext } from "../../../context/CompetitorPriceDataContext";
import { PriceDataContext } from "../../../context/PriceDataContext";
import { formatDateToString } from "../../../util/util";
import { useLocalStorage } from "../../../hooks/useLocalStorage";

const CompetitorPrices = () => {
  const competitorPriceContext = useContext(CompetitorPriceDataContext);
  const { selectedPriceData } = useContext(PriceDataContext);
  const [columnSizing, setColumnSizing] = useLocalStorage(
    "competitorPrices_columnSizing",
    []
  );
  const columns = [
    {
      accessorKey: "competitor",
      header: "Competitor",
      size: 100,
      enableSorting: false,
    },
    { accessorKey: "brand", header: "Brand", size: 50, enableSorting: false },
    {
      accessorKey: "dateTime",
      header: "Date and Time",
      size: 100,
      enableSorting: false,
    },
    { accessorKey: "price", header: "Price", size: 50, enableSorting: false },
    {
      accessorKey: "delta",
      header: "Delta",
      Cell: ({ row }) => {
        const deltaClass =
          parseFloat(row.original.delta) > 0
            ? "positiveDelta"
            : "negativeDelta";
        return <div className={deltaClass}>{row.original.delta}</div>;
      },
      size: 50,
      enableSorting: false,
    },
    { accessorKey: "dist", header: "Dist.", size: 65, enableSorting: false },
    {
      accessorKey: "trigger",
      header: "Trigger",
      size: 50,
      enableSorting: false,
    },
    { accessorKey: "LPC", header: "LPC", size: 50, enableSorting: false },
  ];

  const data = useMemo(() => {
    const initialData = competitorPriceContext
      ? competitorPriceContext.initialData
      : [];
    if (!selectedPriceData || initialData.length === 0) {
      return [];
    }

    return initialData.map((item) => {
      const productPrice = item.productPrice.find(
        (product) =>
          product.productName === selectedPriceData.priceData.productName
      );

      const deltaValue = productPrice
        ? (selectedPriceData.proposedPrice - productPrice.price).toFixed(2)
        : "-";

      return {
        competitor: item.opisId,
        brand: item.brandName,
        dateTime: productPrice
          ? formatDateToString(productPrice.effectiveTime)
          : "-",
        price: productPrice ? `$${productPrice.price.toFixed(3)}` : "-",
        delta: deltaValue,
        dist:
          item && item.distance != null
            ? `${item.distance.toFixed(2)} mile(s)`
            : "-",
        trigger: productPrice && productPrice.trigger ? "✔" : "-",
        LPC: "-",
      };
    });
  }, [competitorPriceContext, selectedPriceData]);

  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      muiPaginationProps={{ showRowsPerPage: false }}
      enablePagination={false}
      enableSorting={false}
      enableTopToolbar={false}
      positionToolbarAlertBanner={"none"}
      enableBottomToolbar={false}
      enableGlobalFilter={false}
      enableFullScreenToggle={false}
      enableDensityToggle={false}
      enableColumnActions={false}
      enableColumnFilters={false}
      enableHiding={false}
      enableColumnResizing={true}
      state={{ columnSizing }}
      onColumnSizingChange={setColumnSizing}
      // muiTableContainerProps={{
      //   sx: {
      //     maxHeight: "15vh",
      //     overflowY: "auto",
      //   },
      // }}
    />
  );
};

export default CompetitorPrices;
