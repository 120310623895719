import { useState } from "react";
import axios from "axios";
import { constructESHeader, formServiceUrl } from "../util/apiUrlUtil";
import constants from "../util/constants/constants.json";
import { ICompetitorPriceData } from "../models/ICompetitorPriceData";

const useElasticSearchQuery = (
  size: number,
  distance: string,
  latitude: number,
  longitude: number
) => {
  const [data, setData] = useState<ICompetitorPriceData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const performQuery = async () => {
    setLoading(true);
    setError(null);

    try {
      const headers = await constructESHeader();
      const url = await formServiceUrl(
        constants.urlConstants.elasticSearch.name,
        constants.urlConstants.elasticSearch.geolocationSearch
      );
      const response = await axios.post(
        url,
        {
          size: size,
          _source: [
            "importcode",
            "displayname",
            "isCompetitor",
            "brandname",
            "location",
          ],
          sort: [
            {
              _geo_distance: {
                location: {
                  lat: latitude,
                  lon: longitude,
                },
                order: "asc",
                unit: "mi",
              },
            },
          ],
          query: {
            bool: {
              must: {
                match_all: {},
              },
              filter: {
                geo_distance: {
                  distance: distance,
                  location: {
                    lat: latitude,
                    lon: longitude,
                  },
                },
              },
            },
          },
        },
        { headers }
      );
      setData(response.data.hits.hits.map((hit: any) => hit._source));
      setLoading(false);
      return response.data;
    } catch (err) {
      setError(err);
      setLoading(false);
      throw err;
    }
  };

  return { data, loading, error, performQuery };
};

export default useElasticSearchQuery;
