/* eslint-disable no-console */
import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  MRT_RowSelectionState,
  useMaterialReactTable,
} from "material-react-table";
import TextField from "@mui/material/TextField";
import { PriceDataContext } from "../../../context/PriceDataContext";
import { CompetitorPriceDataContext } from "../../../context/CompetitorPriceDataContext";
import {
  FormControlLabel,
  InputAdornment,
  Switch,
  Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { Controller, useFormContext } from "react-hook-form";
import { functionalUpdate } from "@tanstack/react-table";

const RuleLowestCompetitorWithOffsetSettings = ({ rule, strategy }) => {
  const {
    register,
    setValue,
    control,
    watch,
    formState: { errors, dirtyFields },
  } = useFormContext();

  register("competitorSelections");
  const priceDataCtx = useContext(PriceDataContext);

  const { selectedPriceData } = useContext(PriceDataContext);
  const { initialData } = useContext(CompetitorPriceDataContext);
  const [isShowInactive, setIsShowInactive] = useState(false);

  const proposedPrice = priceDataCtx?.selectedPriceData?.proposedPrice;

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const competitorFormData = watch("competitorOffsets");
  const maxOpisAge = watch("OpisAge.validationData.opisAge");

  useEffect(() => {
    // When a new strategy was loaded, set the initial selections.
    let config: Record<string, boolean> = {};
    strategy?.competitorOffsets?.forEach((item) => {
      config[item?.competitorId] = true;
    });
    setRowSelection(config);
  }, [strategy]);

  const getIsSelected = (opisIdToCheck) => {
    return getSelectedStores().has(opisIdToCheck);
  };

  const getSelectedStores = () => {
    let selections = new Set();
    // Grab the stores that were selected on the strategy
    strategy?.competitorOffsets?.forEach((offset) => {
      selections.add(offset.competitorId);
    });

    // Also add anything we've checked that wasn't already checked.
    table?.getSelectedRowModel()?.rows?.forEach((row) => {
      selections.add(row._valuesCache["opisId"]);
    });

    return selections;
  };

  const getIndexByCompetitorId = (id) => {
    for (let i = 0; i < competitorFormData.length; i++) {
      if (id == competitorFormData[i].competitorId) {
        return i;
      }
    }
    return null;
  };

  const boundaryCompetitors = watch(
    "CompetitorBoundary.validationData.competitorBoundary"
  );
  const getBoundaryCompetitorIndexByOpisID = (opisId) => {
    // console.log(`opisId: ${opisId}  boundaryCompetitors: ${JSON.stringify(boundaryCompetitors)}`);
    for (let i = 0; i < boundaryCompetitors?.length; i++) {
      let competitor = boundaryCompetitors[i];
      if (competitor.opisId.toString() == opisId.toString()) return i;
    }
    return null;
  };

  const columns = [
    {
      accessorKey: "offset",
      header: "Offset",
      size: 75,
      grow: false,
      enableEditing: true,
      Cell: ({ row }) => {
        const rowIndex = getIndexByCompetitorId(row.id);
        const fieldName = `competitorOffsets.${rowIndex}.offset`;
        if(rowIndex == null) return (<div>loading...</div>);
        return (
          <div
            className={`offset-input dollars ${
              dirtyFields?.competitorOffsets != undefined &&
              rowIndex in dirtyFields?.competitorOffsets &&
              dirtyFields?.competitorOffsets[rowIndex]?.offset
                ? "hasPendingChange"
                : ""
            }
            ${
              errors?.competitorOffsets != undefined &&
              rowIndex in errors?.competitorOffsets &&
              errors?.competitorOffsets[rowIndex]?.offset
                ? "hasError"
                : ""
            }`}
          >
            <Controller
              name={fieldName}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className={
                    field.value == null ||
                    (typeof field.value == "string" && field.value == "")
                      ? "empty-value"
                      : ""
                  }
                  onChange={(newVal) => {
                    field.onChange(newVal);
                    if (!row.getIsSelected()) {
                      row.toggleSelected();
                    }
                  }}
                  type="number"
                  inputMode="decimal"
                  inputProps={{ step: "0.01" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
        );
      },
    },
    {
      accessorKey: "opisId",
      header: "OpisId",
      maxSize: 50,
      grow: true,
      enableSorting: false,
    },
    {
      accessorKey: "fpisId",
      header: "FPIS ID",
      maxSize: 130,
      grow: true,
      enableSorting: false,
    },
    {
      accessorKey: "brand",
      header: "Brand",
      maxSize: 50,
      enableSorting: false,
      grow: true,
    },
    {
      accessorKey: "dateTime",
      header: "EffDateTime",
      maxSize: 120,
      grow: false,
      enableSorting: false,
      Cell: ({ cell }) => {
        const now = new Date();
        const effDate = new Date(cell.getValue());
        const hoursPassed = Math.abs(now.getTime() - effDate.getTime()) / 36e5; // This calculation should use the store's local timezone for NOW part.
        const isStale = hoursPassed > maxOpisAge;

        // return isStale ? (
        //   <Tooltip
        //     title={`This price age currently exceeds the max opis age of ${maxOpisAge} hours.`}
        //     arrow
        //   >
        //     <div className="stale">{cell.getValue()}</div>
        //   </Tooltip>
        // ) : (
        //   <div>{cell.getValue()}</div>
        // );

        return <div>{cell.getValue()}</div>;
      },
    },
    {
      accessorKey: "dist",
      header: "Dist.",
      maxSize: 55,
      enableSorting: false,
      grow: false,
    },
    {
      accessorKey: "minCompetitorBoundary",
      header: "Min Boundary",
      Header: "Min Bndry",
      size: 75,
      grow: false,
      enableEditing: true,
      Cell: ({ cell, column, row, table }) => {
        // Boundary data comes from a different data source (validations) so we need to align the indexes.
        const boundaryIndex = getBoundaryCompetitorIndexByOpisID(row.id);
        // const rowIndex = getIndexByCompetitorId(boundaryIndex);
        const fieldName = `CompetitorBoundary.validationData.competitorBoundary.${boundaryIndex}.lowerThreshold`;
        // console.log(`Fieldname: ${fieldName} boundaryIndex: ${boundaryIndex} `);
        return (
          <div
            className={`offset-input ${
              dirtyFields?.CompetitorBoundary?.validationData
                ?.competitorBoundary != undefined &&
              boundaryIndex in
                dirtyFields?.CompetitorBoundary?.validationData
                  ?.competitorBoundary &&
              dirtyFields?.CompetitorBoundary?.validationData
                ?.competitorBoundary[boundaryIndex]?.lowerThreshold
                ? "hasPendingChange"
                : ""
            }
            ${
              (errors as any)?.CompetitorBoundary?.validationData
                ?.competitorBoundary != undefined &&
              boundaryIndex in
                (errors as any)?.CompetitorBoundary?.validationData
                  ?.competitorBoundary &&
              (errors as any)?.CompetitorBoundary?.validationData
                ?.competitorBoundary[boundaryIndex]?.lowerThreshold
                ? "hasError"
                : ""
            }`}
          >
            <Controller
              name={fieldName}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className={
                    field.value == null ||
                    (typeof field.value == "string" && field.value == "")
                      ? "empty-value"
                      : ""
                  }
                  type="number"
                  inputMode="decimal"
                  inputProps={{ step: "1" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">¢</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
        );
      },
    },
    {
      accessorKey: "price",
      header: "Price",
      maxSize: 50,
      grow: false,
      enableSorting: false,
      Cell: ({ cell, row }) => {
        const boundaryIndex = getBoundaryCompetitorIndexByOpisID(row.id);
        const lowBoundOffset = watch(
          `CompetitorBoundary.validationData.competitorBoundary.${boundaryIndex}.lowerThreshold`
        );
        const upperBoundOffset = watch(
          `CompetitorBoundary.validationData.competitorBoundary.${boundaryIndex}.upperThreshold`
        );

        const lowerBound =
          Number(cell.getValue().replace("$", "")) +
          Number(lowBoundOffset) / 100;
        const upperBound =
          Number(cell.getValue().replace("$", "")) +
          Number(upperBoundOffset) / 100;

        let lowerBoundText = "";
        let upperBoundText = "";

        if (!!lowBoundOffset) {
          lowerBoundText = `${lowerBound.toFixed(3)} < `;
        }

        if (!!upperBoundOffset) {
          upperBoundText = ` < ${upperBound.toFixed(3)}`;
        }

        const isOutsideBoundary =
          (proposedPrice < lowerBound && !!lowBoundOffset) ||
          (proposedPrice > upperBound && !!upperBoundOffset);

        return isOutsideBoundary ? (
          <Tooltip
            title={`Last proposed price exceeds configured boundaries! [${lowerBoundText}${proposedPrice}${upperBoundText}]`}
            arrow
          >
            <div className="stale">{cell.getValue()}</div>
          </Tooltip>
        ) : (
          <div>{cell.getValue()}</div>
        );
      },
    },
    {
      accessorKey: "maxCompetitorBoundary",
      header: "Max Boundary",
      Header: "Max Bndry",
      size: 75,
      grow: false,
      enableEditing: true,
      Cell: ({ cell, column, row, table }) => {
        // Boundary data comes from a different data source (validations) so we need to align the indexes.
        const boundaryIndex = getBoundaryCompetitorIndexByOpisID(row.id);
        // const rowIndex = getIndexByCompetitorId(boundaryIndex);
        const fieldName = `CompetitorBoundary.validationData.competitorBoundary.${boundaryIndex}.upperThreshold`;
        // console.log(`Fieldname: ${fieldName} boundaryIndex: ${boundaryIndex} `);
        return (
          <div
            className={`offset-input ${
              dirtyFields?.CompetitorBoundary?.validationData
                ?.competitorBoundary != undefined &&
              boundaryIndex in
                dirtyFields?.CompetitorBoundary?.validationData
                  ?.competitorBoundary &&
              dirtyFields?.CompetitorBoundary?.validationData
                ?.competitorBoundary[boundaryIndex]?.upperThreshold
                ? "hasPendingChange"
                : ""
            }
            ${
              (errors as any)?.CompetitorBoundary?.validationData
                ?.competitorBoundary != undefined &&
              boundaryIndex in
                (errors as any)?.CompetitorBoundary?.validationData
                  ?.competitorBoundary &&
              (errors as any)?.CompetitorBoundary?.validationData
                ?.competitorBoundary[boundaryIndex]?.upperThreshold
                ? "hasError"
                : ""
            }`}
          >
            <Controller
              name={fieldName}
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <>
                  <TextField
                    {...field}
                    value={field.value}
                    className={
                      field.value == null ||
                      (typeof field.value == "string" && field.value == "")
                        ? "empty-value"
                        : ""
                    }
                    type="number"
                    inputMode="decimal"
                    inputProps={{ step: "1" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">¢</InputAdornment>
                      ),
                    }}
                  />
                </>
              )}
            />
          </div>
        );
      },
    },
  ];

  const competitorData = useMemo(() => {
    // Ensure selectedPriceData is not null or undefined
    if (!selectedPriceData && initialData.length === 0) {
      return [];
    }

    // Filter the competitor data based on the selected fuel grade
    return initialData?.map((item) => {
      const productPrice = item?.productPrice?.find(
        (product) =>
          product.productName === selectedPriceData?.priceData?.productName
      );

      const dateTime: Date = new Date(Date.parse(productPrice?.effectiveTime));

      return {
        opisId: item?.opisId,
        fpisId: item?.fpisStoreId,
        // Description - I don't see any data for this?  Displayname?
        brand: item?.brandName,
        dateTime: productPrice
          ? dateTime
              ?.toLocaleTimeString([], {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              })
              .replace(",", "")
          : "-",
        price: productPrice ? `$${productPrice?.price?.toFixed(3)}` : "-",
        delta: productPrice
          ? (selectedPriceData?.proposedPrice - productPrice?.price)?.toFixed(2)
          : "-",
        dist: item.distance ? item.distance?.toFixed(2) + " mi" : "-",
        trigger:
          productPrice && productPrice?.trigger ? productPrice?.trigger : "-",
        LPC: "-",
        offset: 0, //getStrategyOffsetByCompetitorId(item?.opisId),
      };
    });
  }, [initialData, selectedPriceData, strategy]);

  const table = useMaterialReactTable({
    columns: columns,
    data: isShowInactive
      ? competitorData
      : competitorData.filter((row) => {
          return getIsSelected(row.opisId);
        }), //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    initialState: { density: "compact" },
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableRowSelection: true,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableSorting: false,
    editDisplayMode: "cell",
    enableEditing: false,
    enablePagination: false,

    onRowSelectionChange: (selectionUpdater) => {
      const newVal = functionalUpdate(selectionUpdater, rowSelection);
      updateSelectionsFormData(newVal, true);
      setRowSelection(newVal);
    }, //connect internal row selection state to your own
    state: { rowSelection }, //pass our managed row selection state to the table to use
    getRowId: (row) => row.opisId, //give each row a more useful id

    muiTableContainerProps: {
      sx: {
        maxHeight: "15vh",
        overflowY: "auto",
      },
    },

    muiTableHeadCellProps: {
      sx: (theme) => ({
        background: "#F0F9F1",
        borderRight: "1px solid #F0F9F1",
        color: "#333",
        fontSize: "14px",
        paddingTop: "0.6rem",
        paddingBottom: "0.4rem",
      }),
    },
    muiTableBodyCellProps: ({ cell, column, table }) => ({
      sx: (theme) => ({
        fontSize: "13px",
      }),
      onClick: () => {},
    }),
    muiTableBodyProps: {
      sx: (theme) => ({
        "& tr:nth-of-type(odd)": {
          backgroundColor: theme.customColors.darkRow + " !important", // Seems like the default style on this has !important marked so this isn't working without it.
        },
      }),
    },
    // Action buttons (Submit/Cancel)
    renderBottomToolbarCustomActions: (table) => {
      return (
        <div style={{ display: "flex", gap: "0.5rem", order: "1" }}> </div>
      );
    },
  });

  const updateSelectionsFormData = (selectionData, shouldDirty) => {
    console.log(`Setting selection state. ${JSON.stringify(selectionData)}`);
    for (let i = 0; i < competitorFormData.length; i++) {
      // Is this competitor selected?
      let selected = false;
      selected = !!selectionData[competitorFormData[i].competitorId];
      setValue(`competitorOffsets.${i}.isSelected`, selected, {
        shouldDirty: shouldDirty,
      });
    }
  };

  const onChangeShowInactive = (event) => {
    setIsShowInactive(event.target.checked);
  };

  return (
    <>
      {(rule === "RuleLowestCompetitorWithOffset" || rule === "RuleSecondLowestCompetitorWithOffset"|| rule === "RuleAverageCompetitorWithOffset") && (
        <div className="rule-container">
          <div className={"div-wrapper competitor-grid-header"}>
            <div className={"section-label"}>Competitors</div>
            {table?.getSelectedRowModel()?.rows?.length == 0 && (
              <Typography className="table-validation-message">
                Must select at least one!
              </Typography>
            )}
          </div>
          <div className={"rule-dropdown-container"}>
            <MaterialReactTable table={table} />
            <FormControlLabel
              control={
                <Switch
                  checked={isShowInactive}
                  onChange={onChangeShowInactive}
                />
              }
              label="Show inactive strategy competitors"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default RuleLowestCompetitorWithOffsetSettings;
