// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-bar {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;
  align-items: center;
  width: 100%;
}

.action-bar .adjust-all,
.action-bar .adjust-all .button-container,
.action-bar .action-buttons {
  display: flex;
  align-items: center;
  justify-items: center;
}

.action-bar .adjust-all {
  grid-column: 3/5;
  transform: translateX(-24.8%);
}

.action-bar .adjust-all .button-container {
  display: flex;
  flex-direction: column;
}

.action-bar .adjust-all .button-container button {
  background: none;
  border: none;
  line-height: 1;
  padding: 0 2px;
  margin-left: 1px;
}
.action-bar .adjust-all .button-container button:disabled {
  cursor: not-allowed;
}

.action-bar .adjust-all .label {
  font-weight: lighter;
}

.action-bar .action-buttons {
  justify-self: end;
  grid-column: 8 / 13;
}
.action-bar .action-buttons button {
  font-weight: lighter !important;
}
/* .action-bar .action-buttons button + button {
  margin-left: 16px;
} */
`, "",{"version":3,"sources":["webpack://./src/components/PriceReview/ActionBar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sCAAsC;EACtC,SAAS;EACT,mBAAmB;EACnB,WAAW;AACb;;AAEA;;;EAGE,aAAa;EACb,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,cAAc;EACd,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,+BAA+B;AACjC;AACA;;GAEG","sourcesContent":[".action-bar {\n  display: grid;\n  grid-template-columns: repeat(12, 1fr);\n  gap: 16px;\n  align-items: center;\n  width: 100%;\n}\n\n.action-bar .adjust-all,\n.action-bar .adjust-all .button-container,\n.action-bar .action-buttons {\n  display: flex;\n  align-items: center;\n  justify-items: center;\n}\n\n.action-bar .adjust-all {\n  grid-column: 3/5;\n  transform: translateX(-24.8%);\n}\n\n.action-bar .adjust-all .button-container {\n  display: flex;\n  flex-direction: column;\n}\n\n.action-bar .adjust-all .button-container button {\n  background: none;\n  border: none;\n  line-height: 1;\n  padding: 0 2px;\n  margin-left: 1px;\n}\n.action-bar .adjust-all .button-container button:disabled {\n  cursor: not-allowed;\n}\n\n.action-bar .adjust-all .label {\n  font-weight: lighter;\n}\n\n.action-bar .action-buttons {\n  justify-self: end;\n  grid-column: 8 / 13;\n}\n.action-bar .action-buttons button {\n  font-weight: lighter !important;\n}\n/* .action-bar .action-buttons button + button {\n  margin-left: 16px;\n} */\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
