import React from "react";

interface PriceDisplayProps {
  tempPrice: string;
  onBlur: () => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PriceEdit: React.FC<PriceDisplayProps> = ({ tempPrice, onBlur, onKeyDown, onChange }) => {
  return (
      <input
          type="number"
          value={parseFloat(tempPrice)}
          autoFocus
          onFocus={(e) => e.currentTarget.select()}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onChange={onChange}
          step="0.01"
      />
  );
};

export default PriceEdit;
