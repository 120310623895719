// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.price-input {
    display: inline-flex;
    align-items: center;
    border: 2px solid #B9BBC5;
    border-radius: 0;
    padding: 0 4px;
    background-color: #ffffff;
    max-width: fit-content; /* This will make .price-input only as wide as it needs to be */

}
.price-input input {
    max-width: 3.72em;
}

.price-input .value {
    padding: 0 2px;
    text-align: right;
    font-weight: 900;
    max-width: 3.72em;
}


.price-input .value.clickable {
    cursor: pointer;
}
.price-input.disabled {
    background: rgb(229, 229, 229);

}
.price-input .button-container {
    display: flex;
    flex-direction: column;
}

.price-input .button-container button {
    background: none;
    border: none;
    line-height: 1;
    padding: 0 2px;
}

.price-input .button-container button:disabled {
    cursor: not-allowed;

}

.price-input .disabled {
    background-color: #B9BBC5;
}
`, "",{"version":3,"sources":["webpack://./src/components/PriceReview/PriceInput/PriceInput.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,mBAAmB;IACnB,yBAAyB;IACzB,gBAAgB;IAChB,cAAc;IACd,yBAAyB;IACzB,sBAAsB,EAAE,+DAA+D;;AAE3F;AACA;IACI,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;AACrB;;;AAGA;IACI,eAAe;AACnB;AACA;IACI,8BAA8B;;AAElC;AACA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,mBAAmB;;AAEvB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".price-input {\n    display: inline-flex;\n    align-items: center;\n    border: 2px solid #B9BBC5;\n    border-radius: 0;\n    padding: 0 4px;\n    background-color: #ffffff;\n    max-width: fit-content; /* This will make .price-input only as wide as it needs to be */\n\n}\n.price-input input {\n    max-width: 3.72em;\n}\n\n.price-input .value {\n    padding: 0 2px;\n    text-align: right;\n    font-weight: 900;\n    max-width: 3.72em;\n}\n\n\n.price-input .value.clickable {\n    cursor: pointer;\n}\n.price-input.disabled {\n    background: rgb(229, 229, 229);\n\n}\n.price-input .button-container {\n    display: flex;\n    flex-direction: column;\n}\n\n.price-input .button-container button {\n    background: none;\n    border: none;\n    line-height: 1;\n    padding: 0 2px;\n}\n\n.price-input .button-container button:disabled {\n    cursor: not-allowed;\n\n}\n\n.price-input .disabled {\n    background-color: #B9BBC5;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
