import IPriceData from "./IPriceData";
import { getProductDisplayProperties } from "../../util/util";
import { DisplayProperties } from "../IColorProperties";

export default class PriceDataModel {
  status: "APPROVED" | "REJECTED" | null = null;
  proposedPrice: number;
  priceData: IPriceData;
  productId: string;
  createdBy:string;
  forceGenerating: boolean = false;
  submitting: boolean = false;

  validations: Array<object>;

  constructor(priceData: IPriceData) {
    this.priceData = priceData;
    this.proposedPrice = priceData.latestPriceGenPrice;
    this.productId = priceData.productId;
    this.validations = priceData.validations;
    this.createdBy = priceData.createdBy
  }

  get priceAge(): number {
    if (this.priceData.priceAge) {
      return this.priceData.priceAge;
    }
    const { lastExportedPriceEffectiveDate } = this.priceData;

    if (!lastExportedPriceEffectiveDate) {
      return 0;
    }

    const latestDate = new Date(lastExportedPriceEffectiveDate);
    const today = new Date();

    if (isNaN(latestDate.getTime()) || isNaN(today.getTime())) {
      throw new Error("Invalid date format provided.");
    }

    const timeDiffMilliseconds = today.getTime() - latestDate.getTime();
    const differenceInDays = timeDiffMilliseconds / (1000 * 60 * 60 * 24);

    return Math.round(differenceInDays);
  }

  get displayName(): string {
    return this.getProductMetadata().displayText || this.productId;
  }

  get backgroundColor(): string {
    return this.getProductMetadata().backgroundColor;
  }

  get textColor(): string {
    return this.getProductMetadata().textColor;
  }

  get description(): {
    status: string;
    statusComments: string;
    originalPrice: number;
  } {
    return {
      status: this.priceData.latestPriceGenPriceStatus,
      originalPrice: this.priceData.latestPriceGenPrice,
      statusComments: this.priceData.statusComments,
    };
  }

  get strategy(): string {
    // if "Linked" in strategy, return "Linked"
    // else return "Rule"
    return this.priceData.strategy?.includes("Link") ? "Linked" : "Rule";
  }

  get disabled(): boolean {
    return (
      this.priceData.latestPriceGenPriceStatus !== "PENDING" ||
      this.forceGenerating
    );
  }

  get rejected(): boolean {
    return this.status === "REJECTED";
  }

  public getProductMetadata(): DisplayProperties {
    let productDisplayProperties = getProductDisplayProperties(this.productId);
    if (!productDisplayProperties.displayText) {
      productDisplayProperties.displayText =
        this.priceData.productName.substring(0, 3);
    }
    return productDisplayProperties;
  }
}
