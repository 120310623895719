import React, { useEffect, useState } from "react";
import {
  Box,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const MinMaxChangeValidationConfig = ({ validatorData, onChange }) => {
  const {
    register,
    control,
    formState: { errors, isDirty, dirtyFields },
  } = useFormContext();

  return (
    <div>
      <Box component="form" className="validatorConfigPanel">
        <Controller
          name="MaxIncreaseDecrease.enabled.flag"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              className="enableSwitch"
              control={<Switch {...field} checked={field.value} />}
              label="Enabled"
            />
          )}
        />
        <Controller
          name="MaxIncreaseDecrease.validationData.maxDecrease"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              error={
                !!(errors as any)?.MaxIncreaseDecrease?.validationData
                  ?.maxDecrease
              }
              label="Max Decrease"
              variant="standard"
              type="number"
              inputProps={{
                step: 0.01,
                min: 0,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          )}
        />
        <Controller
          name="MaxIncreaseDecrease.validationData.maxIncrease"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              error={
                !!(errors as any)?.MaxIncreaseDecrease?.validationData
                  ?.maxIncrease
              }
              label="Max Increase"
              variant="standard"
              type="number"
              inputProps={{
                step: 0.01,
                min: 0,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          )}
        />
      </Box>
      <Typography variant="h1">Action to take on failure condition</Typography>
      <hr />
      <Controller
        name="MaxIncreaseDecrease.onFailAction"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            label="Failure Action"
            error={!!(errors as any)?.MaxIncreaseDecrease?.onFailAction}
          >
            <MenuItem value="BlockPriceGenCycle">Block Price Export</MenuItem>
            <MenuItem value="MaxLimit">Limit Price</MenuItem>
            <MenuItem value="RequireApproval">Require Manual Approval</MenuItem>
          </Select>
        )}
      />
    </div>
  );
};

export default MinMaxChangeValidationConfig;
