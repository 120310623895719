import { constructHeader } from "./apiUrlUtil";
import axios, {AxiosError, AxiosResponse} from "axios";
import { getUserName } from "./util";

const apiClient = axios.create({
  headers: {
    ...constructHeader(),
    username: getUserName(),
  },
});
export default apiClient;
