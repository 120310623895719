import { constructHeader } from "./apiUrlUtil";
import axios, { AxiosError, AxiosResponse } from "axios";
import { getUserName } from "./util";
import { tracingInterceptor } from "@7eleven/se-traced-axios";

const apiClient = axios.create({
  headers: {
    ...constructHeader(),
    username: getUserName(),
  },
});
apiClient.interceptors.request.use((config) => {
  // tracingInterceptor(config);
  return config;
});
export default apiClient;
