import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const MinMaxPriceValidationConfig = ({ validatorData, onChange }) => {
  const {
    register,
    control,
    formState: { errors, isDirty, dirtyFields },
  } = useFormContext();

  return (
    <div>
      <Box component="form" className="validatorConfigPanel">
        <Controller
          name="PriceFallUnderMinMax.enabled.flag"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              className="enableSwitch"
              control={<Switch {...field} checked={field.value} />}
              label="Enabled"
            />
          )}
        />
        <Controller
          name="PriceFallUnderMinMax.validationData.minPrice"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              error={
                !!(errors as any)?.PriceFallUnderMinMax?.validationData
                  ?.minPrice
              }
              label="Min Price"
              variant="standard"
              type="number"
              inputProps={{
                step: 0.01,
                min: 0,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          )}
        />

        <Controller
          name="PriceFallUnderMinMax.validationData.maxPrice"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              error={!!(errors as any)?.PriceFallUnderMinMax?.validationData?.maxPrice} // Any cast here to bypass typescript validation error.  It doesn't think validationData exists, but it most certainly does.
              label="Max Price"
              variant="standard"
              type="number"
              inputProps={{
                step: 0.01,
                min: 0,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          )}
        />
      </Box>
      <Typography variant="h1">Action to take on failure condition</Typography>
      <hr />
      <Controller
        name="PriceFallUnderMinMax.onFailAction"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            error={!!(errors as any)?.PriceFallUnderMinMax?.onFailAction}
            label="Failure Action"
          >
            <MenuItem value="BlockPriceGenCycle">Block Price Export</MenuItem>
            <MenuItem value="LimitPrice">Limit Price</MenuItem>
            <MenuItem value="RequireApproval">Require Manual Approval</MenuItem>
          </Select>
        )}
      />
    </div>
  );
};

export default MinMaxPriceValidationConfig;
