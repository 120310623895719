import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Theme {
    customColors: {
      darkRow: string;
    };
  }
  interface ThemeOptions {
    customColors: {
      darkRow?: string;
    };
  }
}

const strategyTheme = createTheme({
  customColors: {
    darkRow: "#F3F2F0",
  },
  palette: {
    primary: {
      main: "#008001",
      contrastText: "#FFFFFF",
    }, // primary color green
    background: {
      default: "#faf9f7",
    },
    text: {
      primary: "#333333",
    },
  },
  typography: {
    fontFamily: "Roboto, Helvetica",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none", // Allow mixed case as in mockup.
          height: "28px",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "14px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          height: "28px",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          // Adds a green strip on the left side.
          "&::before": {
            content: "''",
            width: "5px",
            position: "absolute",
            top: 0,
            height: "100%",
            background: "green",
            left: 0,
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px",
          },
          fontSize: "14px",
          fontWeight: "700",
          width: "180px",
          height: "32px",
        },
      },
    },
  },
});
export default strategyTheme;
