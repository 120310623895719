import { IColorProperties } from "../IColorProperties";
import IPriceData from "./IPriceData";

export default class PriceDataModel {
  status: "APPROVED" | "REJECTED" | null = null;
  proposedPrice: number;
  priceData: IPriceData;
  productId: string;
  createdBy: string;
  forceGenerating: boolean = false;
  submitting: boolean = false;
  parentProductId: string | null;
  spread: number | null;
  validations: Array<object>;

  setProposedPrice(value: number): void {
    this.proposedPrice = Number(Number(value).toFixed(3));
  }

  constructor(priceData: IPriceData) {
    this.priceData = priceData;
    this.setProposedPrice(priceData.latestPriceGenPrice);
    this.productId = priceData.productId;
    this.validations = priceData.validations;
    this.createdBy = priceData.createdBy;
    this.parentProductId = null;
    this.spread = null;
  }

  get priceAge(): number {
    if (this.priceData.priceAge) {
      return this.priceData.priceAge;
    }
    const { lastExportedPriceEffectiveDate } = this.priceData;

    if (!lastExportedPriceEffectiveDate) {
      return 0;
    }

    const latestDate = new Date(lastExportedPriceEffectiveDate);
    const today = new Date();

    if (isNaN(latestDate.getTime()) || isNaN(today.getTime())) {
      throw new Error("Invalid date format provided.");
    }

    const timeDiffMilliseconds = today.getTime() - latestDate.getTime();
    const differenceInDays = timeDiffMilliseconds / (1000 * 60 * 60 * 24);

    return Math.round(differenceInDays);
  }

  get isManualPriceSet(): boolean {
    return (
      (this.priceData.latestPriceGenPriceStatus !== "PENDING" &&
        this.proposedPrice !== this.priceData.latestPriceGenPrice) ||
      this.priceData.latestPriceGenPrice === 0.009
    );
  }

  get description(): {
    status: string;
    statusComments: string;
    originalPrice: number;
  } {
    return {
      status: this.priceData.latestPriceGenPriceStatus,
      originalPrice: this.priceData.latestPriceGenPrice,
      statusComments: this.priceData.statusComments,
    };
  }

  get strategy(): string {
    // if "Linked" in strategy, return "Linked"
    // else return "Rule"
    if (this.priceData.strategy == "ManualPrice")
      return this.priceData.strategy;
    return this.priceData.strategy?.includes("Link") ? "Linked" : "Rule";
  }

  get disabled(): boolean {
    return (
      this.priceData.latestPriceGenPriceStatus !== "PENDING" ||
      this.forceGenerating
    );
  }

  get rejected(): boolean {
    return this.status === "REJECTED";
  }

  clone(): PriceDataModel {
    const cloned = Object.assign(
      new PriceDataModel({
        ...this.priceData,
        validations: this.priceData.validations
          ? [...this.priceData.validations]
          : [],
      }),
      this
    );
    return cloned;
  }
}
