import axios from "axios";
import {getEnv} from "../util/apiUrlUtil";

export async function fetchProductMapping(): Promise<void> {
  const sessionMapping = sessionStorage.getItem("productMapping");
  if (sessionMapping) {
    console.debug("Product mapping found in session storage.");
    return;
  }

  console.debug("No product mapping found. Fetching from server...");
  try {
    const env = await getEnv();
    const fullUrl = env.VISOPS_SECRET_priceGenUiProductMappingUrl;
    const response = await axios.get(fullUrl);
    sessionStorage.setItem("productMapping", JSON.stringify(response.data));
    console.debug("Product mapping fetched and stored in session storage.");
  } catch (error) {
    console.error("Error fetching product mapping:", error);
  }
}

export async function fetchHistoryTemplates(): Promise<any> {
  try {
    const env = await getEnv();
    const templatesUrl = env.VISOPS_SECRET_priceGenUiHistoryTemplates;
    const response = await axios.get(templatesUrl);
    console.debug("History templates fetched successfully.");
    return response.data;
  } catch (error) {
    console.error("Error fetching history templates:", error);
    throw error; // Rethrow the error to handle it in the calling code
  }
}