import UpIcon from "../resources/up.svg";
import DownIcon from "../resources/down.svg";
import CheckmarkIcon from "../resources/checkmark.svg";
import InfoIcon from "../resources/info.svg";
import DenyIcon from "../resources/deny.svg";
import DenyRedIcon from "../resources/deny-red.svg";
import DenyDisabledIcon from "../resources/deny-disabled.svg";
import CheckmarkGreenIcon from "../resources/checkmark-green.svg";
import CheckmarkDisabledIcon from "../resources/checkmark-disabled.svg";
import InfoApprovedIcon from "../resources/info-approved.svg";
import InfoRejectedIcon from "../resources/info-rejected.svg";
import InfoExportedIcon from "../resources/info-exported.svg";
import InfoAlertIcon from "../resources/info-alert.svg";
import Handlebars from "handlebars";

import { USER_NAME_KEY } from "./constants/stringConstants";
import {
  DisplayProperties,
  IColorProperties,
} from "../models/IColorProperties";

export function parseDate(itemElement: string): Date {
  // parse a date string that comes in two ways:
  // 1. 2024-03-26T16:14:23.000Z and 1. 2024-03-26 04:07:50
  try {
    if (!itemElement.includes("T")) {
      return new Date(itemElement.replace(" ", "T").replace("Z", ""));
    } else {
      return new Date(itemElement);
    }
  } catch (e) {
    console.error("Error parsing date: ", e);
    return new Date();
  }
}

export const iconList = {
  up: UpIcon,
  down: DownIcon,
  info: InfoIcon,
  deny: DenyIcon,
  denyRed: DenyRedIcon,
  denyDisabled: DenyDisabledIcon,
  checkmark: CheckmarkIcon,
  checkmarkGreen: CheckmarkGreenIcon,
  checkmarkDisabled: CheckmarkDisabledIcon,
  infoApproved: InfoApprovedIcon,
  infoRejected: InfoRejectedIcon,
  infoExported: InfoExportedIcon,
  infoAlert: InfoAlertIcon,
};

export const getImagePath = (imageName: string) => `${iconList[imageName]}`;
const colors = {
  yellow: "#FFC81E",
  red: "#F34E1F",
  blue: "#0156FF",
  green: "#1CA070",
  black: "#000000",
  white: "#FFFFFF",
};

function createProductDisplayProperties(
  backgroundColor: string,
  textColor: string,
  displayText: string
): DisplayProperties {
  return { backgroundColor, textColor, displayText };
}

export const getProductDisplayProperties = (
  productId: string
): DisplayProperties => {
  const defaultDisplayProperties = createProductDisplayProperties(
    colors.white,
    colors.black,
    ""
  );
  // get session storage
  const sessionMapping = sessionStorage.getItem("productMapping");
  if (!sessionMapping) {
    return defaultDisplayProperties;
  }
  const mapping: IColorProperties[] = JSON.parse(sessionMapping);
  return (
    mapping.find((mappingItem) => mappingItem.productId === productId)
      ?.displayProperties || defaultDisplayProperties
  );
};

export const formatDateToString = (dateInput: Date | string | number) => {
  const date = new Date(dateInput);

  // Check if date is invalid
  if (isNaN(date.getTime())) {
    throw new Error("Invalid date input");
  } else if (dateInput === null) {
    return "";
  }

  const mm = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() is zero-based
  const dd = String(date.getDate()).padStart(2, "0");
  const yy = String(date.getFullYear()).slice(-2);

  const hours = String(date.getHours()).padStart(2, "0"); // 24-hour format
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${mm}/${dd}/${yy} ${hours}:${minutes}`;
};

export function calculateHorizontalDistanceInEms(
  inputLeft: number,
  tableLeft: number
) {
  const distanceInPixels = inputLeft - tableLeft;
  const baseFontSize = parseFloat(
    getComputedStyle(document.documentElement).fontSize
  );
  return distanceInPixels / baseFontSize;
}

export function getUserName() {
  return sessionStorage.getItem(USER_NAME_KEY);
}
export function calculateHorizontalDistanceInPixels(
  inputLeft: number,
  tableLeft: number
): number {
  const distanceInPixels = inputLeft - tableLeft;
  return distanceInPixels;
}

export function calculateHorizontalDistanceInVw(
  inputLeft: number,
  tableLeft: number
) {
  const distanceInPixels = inputLeft - tableLeft;
  const viewportWidth = document.documentElement.clientWidth;
  return (distanceInPixels / viewportWidth) * 100; // Convert pixels to vw
}

export function calculateDynamicOffset(viewportWidth: number) {
  const baseViewportWidth = 1920;
  const originalDistanceVw = 9.47861;
  const adjustedDistanceVw = 10.479;

  const baseOffsetVw = originalDistanceVw - adjustedDistanceVw;

  const dynamicOffsetVw = (baseOffsetVw / baseViewportWidth) * viewportWidth;

  return dynamicOffsetVw;
}

Handlebars.registerHelper("formatControlsAndValidationData", function (data) {
  if (Array.isArray(data)) {
    return data
      .map((item) => {
        const { beforeChange, afterChange } = item;
        return formatChanges(beforeChange, afterChange);
      })
      .join(" | ");
  }
  return "";
});

function formatChanges(before: any, after: any): string {
  if (typeof before === "object" && before !== null && typeof after === "object" && after !== null) {
    const keys = new Set([...Object.keys(before), ...Object.keys(after)]);
    return Array.from(keys)
      .map((key) => {
        const beforeValue = before.hasOwnProperty(key) ? before[key] : null;
        const afterValue = after.hasOwnProperty(key) ? after[key] : null;

        const formattedBeforeValue = beforeValue === null ? "Validation Added" : formatNestedObject(beforeValue);
        const formattedAfterValue = afterValue === null ? "Validation Removed" : formatNestedObject(afterValue);

        return `${key}: ${formattedBeforeValue} → ${formattedAfterValue}`;
      })
      .join(", ");
  }
  return `${String(before)} → ${String(after)}`;
}

function formatNestedObject(obj: any): string {
  console.debug("formatNestedObject - obj:", obj);
  if (typeof obj === "object" && obj !== null) {
    return Object.entries(obj)
      .map(([key, value]) => {
        if (typeof value === "object" && value !== null) {
          return `${key}: { ${formatNestedObject(value)} }`;
        }
        return `${key}: ${String(value)}`;
      })
      .join(", ");
  }
  return String(obj);
}

Handlebars.registerHelper("extractProperty", function (property) {
  if (typeof property === "string") {
    const parts = property.split(".");
    if(parts.length  >= 3){
      return parts[parts.length - 2 + parts.length-3];
    }
  }
  return property;
});

Handlebars.registerHelper("formatMessageForControlsAndValidation", function (data) {
  if (Array.isArray(data)) {
    const propertyChanges = Handlebars.helpers.uniqueProperties(data);
    return `${propertyChanges}`;
  }
  return "";
});

Handlebars.registerHelper("uniqueProperties", function (changes: any[]) {
  const propertyCounts = changes.reduce<Record<string, number>>((acc, change) => {
    const property = Handlebars.helpers.extractProperty(change.property);
    acc[property] = (acc[property] || 0) + 1;
    return acc;
  }, {});

  return Object.entries(propertyCounts)
    .map(([property, count]) => (count > 1 ? `${property} (${count})` : property))
    .join(", ");
});

Handlebars.registerHelper("skipIfUndefinedOrNull", function (value, options) {
  if (value !== undefined && value !== null) {
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
});

Handlebars.registerHelper("isCompetitors", function (key, options) {
  if (key === "competitors") {
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
});

Handlebars.registerHelper("productDisplayName", function (productId) {
  const productDisplayProperties = getProductDisplayProperties(productId);
  return productDisplayProperties.displayText || productId; 
});

Handlebars.registerHelper("transformDisplayName", function (key, value) {
  const fieldsToTransform = ["ruleLinkedProduct", "baseProduct"];

  if (fieldsToTransform.includes(key)) {
    const productDisplayProperties = getProductDisplayProperties(value);
    return productDisplayProperties.displayText || value;
  } else {
    return value;
  }
});
export const addDays = (date, days, setToEndOfDay = false) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  if (setToEndOfDay) {
    result.setHours(23, 59, 59, 999);
  }
  return result;
};

export function setToEndOfDay(date: Date) {
  date.setHours(23, 59, 59, 999);
  return date;
}

export function setToStartOfDay(date: Date) {
  date.setHours(0, 0, 0, 0);
  return date;
}

export function daysBetween(date1, date2) {
  const oneDay = 1000 * 60 * 60 * 24;

  // Convert both dates to milliseconds
  const date1_ms = date1.getTime();
  const date2_ms = date2.getTime();

  // Calculate the difference in milliseconds
  const difference_ms = date2_ms - date1_ms;

  // Convert back to days and return
  return Math.round(difference_ms / oneDay);
}
