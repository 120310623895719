import axios from 'axios';
import { useState } from 'react';
import { formServiceUrl, constructHeader, getEnv } from '../util/apiUrlUtil';
import constants from "../util/constants/constants.json";

const useRemoveStores = () => {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const removeStores = async (seiId, opisIds) => {
    setLoading(true);
    setError(null);

    try {
      const headers = constructHeader();
      const serviceUrl = await formServiceUrl(
        constants.urlConstants.priceGenPriceAdminMaster.name,
        constants.urlConstants.priceGenPriceAdminMaster.deleteBySEIIdAndOpisIds
      );
      const result = await axios.delete(serviceUrl, {
        headers: headers,
        data: {
          seiId: seiId,
          opisIds: opisIds,
        }
      });

      setResponse(result.data);
    } catch (err) {
      setError(err.response?.data || { message: 'An unknown error occurred' });
    } finally {
      setLoading(false);
    }
  };

  return { response, loading, error, removeStores };
};

export default useRemoveStores;
