import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiClient from "../util/apiClient";
import { formServiceUrl } from "../util/apiUrlUtil";
import constants from "../util/constants/constants.json";
import { AxiosError } from "axios";

const saveValidation = async (ValidationJsonString: Object) => {
  console.debug("Save request json: " + JSON.stringify(ValidationJsonString));

  // const serviceUrl = "http://localhost:8099/saveValidation";

  const serviceUrl = await formServiceUrl(
    constants.urlConstants.priceGenPriceAdminMaster.name,
    constants.urlConstants.priceGenPriceAdminMaster.saveValidation
  );
  const response = await apiClient.post(serviceUrl, ValidationJsonString);
  return response?.data;
};

export const useSaveValidation = () => {
  const queryClient = useQueryClient();

  return useMutation(saveValidation, {
    onSuccess: (resultData) => {
      queryClient
        .invalidateQueries({
          queryKey: [
            constants.urlConstants.priceGenPriceAdminMaster
              .getValidationQueryKey,
          ],
        })
        .then((r) => {
          console.debug("Refetch complete.");
        });
    },
    onError: (error: AxiosError) => {
      console.debug(
        "Error saving Validation: " + JSON.stringify(error?.response?.data)
      );
    },
  });
};
