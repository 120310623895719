import React from 'react';

const CloseButton = ({ customProps }) => {
    return (
        <div className="close-x-wrapper" style={{ border: '1px solid' }}>
            <svg
                onClick={customProps.compRefClose}
                className={`size-28-1`}
                fill="none"
                height="28"
                viewBox="0 0 28 28"
                width="28"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    className="path"
                    d="M21 7L7 21M7 7L21 21"
                    stroke="#333333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
            </svg>
        </div>
    );
};

export default CloseButton;
