import React, { useState } from "react";
import "../pricegen.css";

const PGIcon = () => {
  return (
    <div className="pg-icon-container">
      <div className="pg-icon-background">
        <div className="pg-icon-text-wrapper">PG</div>
      </div>
    </div>
  );
};

export default PGIcon;
