import { assetUrl } from "./asset-url";
import constants from "./constants/constants.json";
import envLocal from "../assets/env.json";
import CryptoJS from "crypto-js";
import AES from "crypto-js/aes";

class Env {
  private static instance: Env = null;
  private static environment: any;

  public constructor(env: any) {
    if (!Env.instance) {
      Env.environment = env;
    }
    return Env.instance;
  }

  public static getSingletonEnv() {
    return this.environment;
  }
}

export const formServiceUrl = async (
  serviceName: string,
  endpoint: string,
  params?: { [key: string]: string }
) => {
  const env = await getEnv();  const hostname = window && window.location && window.location.hostname;
  // Determine which API host to use based on whether the hostname includes 'localhost'
  const apiHost = hostname.includes("localhost") ? env.VISOPS_SECRET_apigee_host_local : env.VISOPS_SECRET_apigee_host;
  const url = new URL(
    `${constants.urlConstants.protocol}${apiHost}${constants.urlConstants.servicePrefix}${serviceName}${endpoint}`
  );
  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      url.searchParams.append(key, value);
    });
  }
  return url.toString();
};

export const getEnv = () => {
  const hostname = window && window.location && window.location.hostname;
  if (/^.*localhost.*/.test(hostname) || !hostname.includes("visops")) {
    return Promise.resolve(envLocal);
  }

  let env = Env.getSingletonEnv();
  if (env) {
    return Promise.resolve(env);
  }
  return fetch(assetUrl("env.json"))
    .then((res) => res.json())
    .then((env) => {
      // console.debug("ENV ------> ", env);
      new Env(env);
      return env;
    });
};

export const constructHeader = () => {
  const hostname = window && window.location && window.location.hostname;
  if (hostname.includes("localhost") && !hostname.includes("3000")) {
    // If localhost is in the URL, return an empty object or default headers without auth codes
    return {};
  } else {
    // Else, include the authorization and refresh tokens
  return {
      authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
      refresh_token: `${window.sessionStorage.getItem("refreshToken")}`,
    };
  }
};

export const constructESHeader = () => {
  return getEnv().then((env) => {
    return {
      Authorization: AES.decrypt(
        env.VISOPS_SECRET_pricingErrorPassword_encrypt,
        "FCC"
      ).toString(CryptoJS.enc.Utf8),
      "X-Subscription-Key": AES.decrypt(
        env.VISOPS_SECRET_pricingSubscriptionKey_encrypt,
        "FCC"
      ).toString(CryptoJS.enc.Utf8),
      "Content-Type": "application/json",
    };
  });
};
