import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { PriceDataContext } from "./PriceDataContext";
import { getHistoryData } from "../api/getHistoryApi";
import { IAllHistory } from "../models/history/IProductHistory";
import { CompetitorPriceDataContext } from "./CompetitorPriceDataContext";

export interface ProductEntry {
  auditDate: Date;
  historyType: string;
  message: string;
  changeType?: string;
  userName?: string;
  data: any;
}

interface HistoryTabData {
  historyData: IAllHistory;
  dateRange: { fromDate: Date; toDate: Date };
  setDateRange: (dateRange: { fromDate: Date; toDate: Date }) => void;
  isLoading: boolean;
}

export const HistoryTabDataContext = createContext<HistoryTabData>({
  historyData: null,
  dateRange: { fromDate: null, toDate: null },
  setDateRange: () => {},
  isLoading: false,
});

export const HistoryTabDataProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [historyData, setHistoryData] = useState<IAllHistory>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dateRange, setDateRange] = useState<{ fromDate: Date; toDate: Date }>({
    fromDate: null,
    toDate: null,
  });
  const priceDataContext = useContext(PriceDataContext);
  const { priceData, seiId } = priceDataContext;
  const { initialData } = useContext(CompetitorPriceDataContext);

  useEffect(() => {
    if (!priceData || priceData.length === 0 || !initialData) return;
    getHistoryData(
      seiId,
      priceData.map((data) => data.productId),
      initialData.map((data) => data.importCode),
      dateRange.fromDate,
      dateRange.toDate,
      setIsLoading
    ).then((data) => {
      console.debug("Fetched history data", JSON.stringify(data));
      setHistoryData(data);
      console.log("Raw History Data", data);
    });
  
    console.debug("Setting history data");
  }, [priceData, initialData, dateRange]);

  const contextValue = useMemo(
    () => ({
      historyData,
      dateRange,
      setDateRange,
      isLoading,
    }),
    [historyData, setDateRange, dateRange, isLoading]
  );

  return (
    <HistoryTabDataContext.Provider value={contextValue}>
      {children}
    </HistoryTabDataContext.Provider>
  );
};