import { useMutation } from "@tanstack/react-query";
import { getProposedPrices } from "../../api/proposedPrices";
import PriceDataModel from "../../models/priceView/PriceData.model";
import {SetPriceDataType} from "../../types/PriceReview.types";
export const useProposedPricesMutation = (options: {
  seiId: string;
  onSuccess: (data: PriceDataModel[]) => void
  onError: (error: any) => void;
}) => {
  const { seiId, onSuccess, onError } = options;

  const { mutate } = useMutation({
    mutationFn: () => getProposedPrices(seiId),
    onSuccess,
    onError,
  });

  return mutate;
};

export const useFetchAndUpdateProposedPrices = (
    seiId: string,
    setPriceData: SetPriceDataType,
) => {
  // Call generic fetch function and pass component-specific callbacks
  return useProposedPricesMutation({
    seiId,
    onSuccess: (data: PriceDataModel[]) => {
      console.debug("Proposed prices fetched successfully: ", data);
      setPriceData(data);
    },
    onError: (error: any) => {
      console.error("Error fetching proposed prices:", error);
      setPriceData([]);
    },
  });
};
