import React, { useEffect, useState } from "react";
import {
  Box,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";

const PriceMustChangeConfig = ({ validatorData, onChange }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <Box component="form" className="validatorConfigPanel">
        <div>
          <Controller
            name="priceMustChange.enabled.flag"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                className="enableSwitch"
                control={
                  <Switch
                    {...field}
                    checked={field.value}
                    inputProps={{ "aria-label": "controlled" }}
                    disabled={true}
                  />
                }
                label="Enabled"
              />
            )}
          />
          <Typography>Note: This control is always enabled.</Typography>
        </div>
      </Box>
      <Typography variant="h1">Action to take on failure condition</Typography>
      <hr />
      <Controller
        name="priceMustChange.onFailAction"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            label="Failure Action"
            error={!!(errors as any)?.priceMustChange?.onFailAction}
          >
            <MenuItem value="BlockPriceGenCycle">Block Price Export</MenuItem>
          </Select>
        )}
      />
    </div>
  );
};

export default PriceMustChangeConfig;
