import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import "./PriceInput.css";
import { PriceDataContext } from "../../../context/PriceDataContext";
import PriceEdit from "./PriceEdit";
import AdjustPriceButtons from "./AdjustPriceButtons";
import PriceDisplay from "./PriceDisplay";

interface PriceInputProps {
  productId: string;
}

const PriceInput: React.FC<PriceInputProps> = ({ productId }) => {
  const priceDataCtx = useContext(PriceDataContext);
  const { priceData, adjustProposedPrice, updateProposedPrice } = priceDataCtx;

  const [showInput, setShowInput] = useState(false);
  const [tempPrice, setTempPrice] = useState("");

  const priceDataItem = priceData.find((item) => item.productId === productId);
  const proposedPrice = useMemo(() => (
      priceDataItem?.proposedPrice || 0
  ), [priceDataItem]);

  const isRejected = useMemo(() => (
      priceDataItem?.status === "REJECTED"
  ), [priceDataItem]);

  const isPending =
    priceDataItem?.priceData.latestPriceGenPriceStatus === "PENDING";

  const disabled = isRejected || !isPending;

  const inputBackgroundColor =
    isPending && !priceDataItem?.status ? "#F7951C" : "#B9BBC5";

  useEffect(() => {
    if (!priceDataCtx || !priceDataItem) {
      return;
    }
    setTempPrice(
      priceDataItem.proposedPrice ? priceDataItem.proposedPrice.toString() : ""
    );
  }, [priceData]);

  useEffect(() => {
    if (!priceData.length) {
      return;
    }
    if (tempPrice === "") {
      setTempPrice(proposedPrice.toString());
    }
  }, [proposedPrice, tempPrice, priceData.length]);

  const ensureValidPrice = useCallback((value: string) => {
    let number = parseFloat(value);
    if (number <= 0 && priceDataItem) {
      number = parseFloat(priceDataItem.priceData.latestPriceGenPrice.toString());
    }
    return number.toFixed(3);
  }, [priceDataItem]);

  const handleFinalizeInput = useCallback(() => {
    console.debug(`Finalizing input for ${productId}`)
    let number = parseFloat(tempPrice);
    if (number <= 0 ) {
      number = parseFloat(proposedPrice.toString());
    }
    setTempPrice(ensureValidPrice(tempPrice));
    // Update the proposed price
    updateProposedPrice(number, productId);
    setShowInput(false);
  }, [tempPrice, ensureValidPrice]);

  const onKeyDown = useCallback((e) => {
    if (e.key === "ArrowUp") {
      setTempPrice((prevPrice) => (parseFloat(prevPrice) + 0.01).toFixed(3));
      e.preventDefault();
    } else if (e.key === "ArrowDown") {
      setTempPrice((prevPrice) => (parseFloat(prevPrice) - 0.01).toFixed(3));
      e.preventDefault();
    } else if (e.key === "Enter") {
      handleFinalizeInput();
    } else if (e.key === "Escape") {
      setTempPrice(proposedPrice.toString());
      setShowInput(false);
    }
  }, [proposedPrice, handleFinalizeInput]);

  if (!tempPrice) {
    return <></>;
  }
  return (
    <div
      className={`price-input ${disabled ? "disabled" : ""}`}
      style={{ borderColor: inputBackgroundColor }}
    >
      {showInput ? (
        // Render the input field when showInput is true
        <PriceEdit
          tempPrice={tempPrice}
          onBlur={handleFinalizeInput}
          onKeyDown={onKeyDown}
          onChange={(e) => {
            const formattedValue = formatInputValue(e.target.value.toString());
            if (formattedValue !== "") {
              setTempPrice(formattedValue);
            }
          }}
        />
      ) : (
        <>
          <PriceDisplay
            price={parseFloat(tempPrice)}
            disabled={disabled}
            onClick={(e) => {
              e.stopPropagation();
              !disabled && setShowInput(true);
            }}
            // onWheel={handleWheelChange}
          />
          <AdjustPriceButtons
            onIncrement={(e) => {
              e.stopPropagation();
              adjustProposedPrice(true, productId);
            }}
            onDecrement={(e) => {
              e.stopPropagation();
              if (parseFloat(tempPrice) <= 0) {
                return;
              }
              adjustProposedPrice(false, productId);
            }}
            disabled={disabled}
          />
        </>
      )}
    </div>
  );
};
const formatInputValue = (value: string) => {
  const regex = /^-?\d*\.?\d{0,3}$/;
  if (RegExp(regex).exec(value)) {
    return value; // this is valid input; allow it
  }

  // If the invalid input or too many decimal places, restrict the number to 3 decimal places
  const number = parseFloat(value);
  return isNaN(number) ? "" : number.toFixed(3);
};

export default PriceInput;
