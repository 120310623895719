import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import PriceDataModel from "../models/priceView/PriceData.model";

import PriceDataUtilities from "../util/PriceDataUtilities";
import { StatusType } from "../types/PriceReview.types";
import { PriceDataContextType } from "../types/priceDataContext.types";
import useFetchAuditTrail from "../hooks/priceViewApis/useFetchAuditTrail";
import {
  useProposedPricesMutation,
  useFetchAndUpdateProposedPrices,
} from "../hooks/priceViewApis/useFetchAndUpdateProposedPrices";

// Context definition for managing and updating price data.
export const PriceDataContext = createContext<PriceDataContextType | null>(
  null
);

interface PriceDataProviderProps {
  children: ReactNode;
  seiId?: string;
  fpisStoreId?: string;
}

const changeProposedPriceStatus =
  (setPriceData) => (newStatus: StatusType, productId: string) =>
    PriceDataUtilities.changeProposedPriceStatus(
      setPriceData,
      newStatus,
      productId
    );

const adjustProposedPrice =
  (setPriceData) => (increment: boolean, productId: string) =>
    PriceDataUtilities.adjustProposedPrice(setPriceData, increment, productId);

const changeProposedPriceStatusForAll =
  (setPriceData) => (newStatus: StatusType) =>
    PriceDataUtilities.changeProposedPriceStatusForAll(setPriceData, newStatus);

const adjustProposedPriceForAll = (setPriceData) => (increment: boolean) =>
  PriceDataUtilities.adjustProposedPriceForAll(setPriceData, increment);

const areAnyPricesFinalized = (priceData) => () =>
  PriceDataUtilities.areAnyPricesFinalized(priceData);

const updateProposedPrice =
  (setPriceData) => (newPrice: number, productId: string) =>
    PriceDataUtilities.updateProposedPrice(setPriceData, newPrice, productId);

const setProposedPricesStatus =
  (setPriceData) => (proposedPrices: IProposedPrice[]) =>
    PriceDataUtilities.setProposedPriceStatusFromProposedPrices(
      setPriceData,
      proposedPrices
    );

export const PriceDataProvider: React.FC<PriceDataProviderProps> = ({
  children,
  seiId,
  fpisStoreId,
}) => {
  const [priceData, setPriceData] = useState<PriceDataModel[]>([]);
  const [selectedPriceDataIndex, setSelectedPriceDataIndex] = useState(0);
  const [selectedPriceData, setSelectedPriceData] =
    useState<PriceDataModel>(null);

  const refreshProposedPrices = useFetchAndUpdateProposedPrices(
    seiId,
    setPriceData
  );

  const scheduleProposedPricesExportCheck = useProposedPricesMutation({
    seiId: seiId,
    onSuccess: (data) => {
      console.debug("Proposed prices interval fetched successfully: ", data);
      let statusChanged = false; // Flag to track if any status has changed
      const newPriceData = priceData.map((pd) => {
        const newPrice = data.find((d) => d.productId === pd.productId);
        if (
          newPrice &&
          newPrice.priceData.latestPriceGenPriceStatus == "EXPORTED" &&
          pd.priceData.latestPriceGenPriceStatus !== "EXPORTED"
        ) {
          statusChanged = true;
          return newPrice;
        }
        return pd;
      });

      if (statusChanged) {
        setPriceData(newPriceData);
      }
    },
    onError: (error) => {
      console.error("Error fetching proposed prices:", error);
    },
  });

  useEffect(() => {
    refreshProposedPrices();
  }, [seiId]);

  useEffect(() => {
    if (priceData.length === 0) {
      return;
    }

    let newSelectedIndex = selectedPriceDataIndex;

    if (newSelectedIndex == null) {
      newSelectedIndex = 0;
    }
    if (selectedPriceDataIndex > priceData.length - 1) {
      newSelectedIndex = priceData.length - 1;
    }

    setSelectedPriceData(priceData[newSelectedIndex]);
    setSelectedPriceDataIndex(newSelectedIndex);
  }, [priceData]);

  const changeProposedPriceStatusMemo = useCallback(
    changeProposedPriceStatus(setPriceData),
    [setPriceData]
  );
  const adjustProposedPriceMemo = useCallback(
    adjustProposedPrice(setPriceData),
    [setPriceData]
  );
  const changeProposedPriceStatusForAllMemo = useCallback(
    changeProposedPriceStatusForAll(setPriceData),
    [setPriceData]
  );
  const adjustProposedPriceForAllMemo = useCallback(
    adjustProposedPriceForAll(setPriceData),
    [setPriceData]
  );
  const areAllPricesFinalizedMemo = useCallback(
    areAnyPricesFinalized(priceData),
    [priceData]
  );
  const updateProposedPriceMemo = useCallback(
    updateProposedPrice(setPriceData),
    [setPriceData]
  );

  const setProposedPricesStatusMemo = useCallback(
    setProposedPricesStatus(setPriceData),
    [setPriceData]
  );

  const contextValue = useMemo(
    () => ({
      priceData,
      seiId,
      fpisStoreId,
      setPriceData,
      selectedPriceData,
      selectedPriceDataIndex,
      setSelectedPriceData,
      setSelectedPriceDataIndex,
      scheduleProposedPricesExportCheck: scheduleProposedPricesExportCheck,
      fetchGeneratedPrices: refreshProposedPrices,
      changeProposedPriceStatus: changeProposedPriceStatusMemo,
      adjustProposedPrice: adjustProposedPriceMemo,
      changeProposedPriceStatusForAll: changeProposedPriceStatusForAllMemo,
      adjustProposedPriceForAll: adjustProposedPriceForAllMemo,
      areAnyPricesFinalized: areAllPricesFinalizedMemo,
      updateProposedPrice: updateProposedPriceMemo,
      setProposedPricesStatus: setProposedPricesStatusMemo,
    }),
    [
      priceData,
      selectedPriceData,
      selectedPriceDataIndex,
      changeProposedPriceStatusMemo,
      adjustProposedPriceMemo,
      changeProposedPriceStatusForAllMemo,
      adjustProposedPriceForAllMemo,
      areAllPricesFinalizedMemo,
      updateProposedPriceMemo,
      setProposedPricesStatusMemo,
      seiId,
      fpisStoreId,
      scheduleProposedPricesExportCheck,
      refreshProposedPrices,
    ]
  );

  return (
    <PriceDataContext.Provider value={contextValue}>
      {children}
    </PriceDataContext.Provider>
  );
};
