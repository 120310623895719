// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-bar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.buttons-container {
    display: flex;
    justify-content: flex-end;
}
.selectors-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    gap: 1em;
}

.action-bar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.selectors-container select {
    width: 5em;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.buttons-container {
    display: flex;
    justify-content: flex-end;
}

.size-input {
    text-align: center !important;
    width: 3em;
    border-radius: 1em !important;
    border: 1px solid #ccc;
}

.CG-Action-Button, .CG-Grid-Button {
    background-color: green;
    width: 4em;
    color: white;
    padding: 0.5em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 1em;
}

.CG-Action-Button:hover, .CG-Grid-Button:hover {
    background-color: darkgreen;
}

.CG-Action-Button:disabled {
    background-color: grey;
    cursor: not-allowed;
}

.CG-Grid-Button {
    margin-right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/TabContainer/CompetitorGrid/CompetitorGridActionBar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,yBAAyB;AAC7B;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,QAAQ;AACZ;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,6BAA6B;IAC7B,UAAU;IACV,6BAA6B;IAC7B,sBAAsB;AAC1B;;AAEA;IACI,uBAAuB;IACvB,UAAU;IACV,YAAY;IACZ,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,sCAAsC;IACtC,iBAAiB;AACrB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".action-bar-container {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n.buttons-container {\n    display: flex;\n    justify-content: flex-end;\n}\n.selectors-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-grow: 1;\n    gap: 1em;\n}\n\n.action-bar-container {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.selectors-container select {\n    width: 5em;\n    border-radius: 5px;\n    border: 1px solid #ccc;\n}\n\n.buttons-container {\n    display: flex;\n    justify-content: flex-end;\n}\n\n.size-input {\n    text-align: center !important;\n    width: 3em;\n    border-radius: 1em !important;\n    border: 1px solid #ccc;\n}\n\n.CG-Action-Button, .CG-Grid-Button {\n    background-color: green;\n    width: 4em;\n    color: white;\n    padding: 0.5em;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n    margin-right: 1em;\n}\n\n.CG-Action-Button:hover, .CG-Grid-Button:hover {\n    background-color: darkgreen;\n}\n\n.CG-Action-Button:disabled {\n    background-color: grey;\n    cursor: not-allowed;\n}\n\n.CG-Grid-Button {\n    margin-right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
