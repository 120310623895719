// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-td {
  background-color: rgb(245, 245, 245);
}
.action-td:not(.disabled):hover {
  cursor: pointer;
}
.action-td.disabled {
  cursor: default;
}
`, "",{"version":3,"sources":["webpack://./src/components/PriceReview/ActionButtonTd.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB","sourcesContent":[".action-td {\n  background-color: rgb(245, 245, 245);\n}\n.action-td:not(.disabled):hover {\n  cursor: pointer;\n}\n.action-td.disabled {\n  cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
