import React, { useContext, useEffect, useState } from "react";
import {
  MaterialReactTable,
  MRT_RowSelectionState,
} from "material-react-table";
import { CompetitorPriceDataContext } from "../../../context/CompetitorPriceDataContext";
import "./CompetitorGrid.css";
import CompetitorGridActionBar from "./CompetitorGridActionBar";
import useElasticSearchQuery from "../../../hooks/useElasticSearchQuery";
import { PriceDataContext } from "../../../context/PriceDataContext";
import { formatDateToString } from "../../../util/util";
import useRemoveStores from "../../../hooks/useRemoveStores";
import useGetOpisIds from "../../../hooks/useGetOpisIds";
import useCreateStoreCompetitorMapping from "../../../hooks/useCreateStoreCompetitorMapping";
import { useLocalStorage } from "../../../hooks/useLocalStorage";

const CompetitorGrid = ({ propData }) => {
  const {
    initialData,
    gridState,
    setGridState,
    distance: contextDistance,
    setDistance: setContextDistance,
    size: contextSize,
    setSize: setContextSize,
  } = useContext(CompetitorPriceDataContext);
  const [selectedRowStateAdding, setSelectedRowStateAdding] =
    useState<MRT_RowSelectionState>({});
  const [selectedRowStateRemoving, setSelectedRowStateRemoving] =
    useState<MRT_RowSelectionState>({});
  const [esData, setEsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { data, error, performQuery } = useElasticSearchQuery(
    contextSize,
    contextDistance,
    propData?.coords?.latitude,
    propData?.coords?.longitude
  );
  const { removeStores } = useRemoveStores();
  const { selectedPriceData, seiId } = useContext(PriceDataContext);
  const { fetchData } = useContext(CompetitorPriceDataContext);
  const { getOpisIds } = useGetOpisIds();
  const { createMapping } = useCreateStoreCompetitorMapping();

  const [columnSizing, setColumnSizing] = useLocalStorage(
    "competitorGrid_columnSizing",
    []
  );

  const handleRemove = async () => {
    const selectedRowIndices = Object.keys(selectedRowStateRemoving).filter(
      (key) => selectedRowStateRemoving[key]
    );

    const selectedOpisIDs = selectedRowIndices.map((index) => {
      const rowIndex = parseInt(index, 10);
      return initialData[rowIndex]?.opisId;
    });

    if (selectedOpisIDs.length > 0) {
      setLoading(true);
      try {
        await removeStores(seiId, selectedOpisIDs);
        setSelectedRowStateRemoving({});
        console.debug(
          `triggered removing competitor for SEI store ${seiId} for its competitors ${selectedOpisIDs}`
        );
      } catch (error) {
        console.error("Error removing stores:", error);
      } finally {
        setLoading(false);
        await fetchData();
      }
    }
  };

  const handleAdd = async () => {
    setLoading(true);
    setSelectedRowStateAdding({});
    setSelectedRowStateRemoving({});

    const selectedRowIndices = Object.keys(selectedRowStateAdding).filter(
      (key) => selectedRowStateAdding[key]
    );

    const selectedOpisIDs = selectedRowIndices.map((index) => {
      const rowIndex = parseInt(index, 10);
      return esData[rowIndex]?.OpisID;
    });

    if (selectedOpisIDs.length > 0) {
      await createMapping(seiId, selectedOpisIDs);
      setSelectedRowStateAdding({});
      setGridState("removing");
    }
  };

  const toggleGridState = () => {
    setSelectedRowStateAdding({});
    setSelectedRowStateRemoving({});
    if (gridState === "removing") {
      setGridState("adding");
    } else if (gridState === "adding") {
      setGridState("removing");
    }
    // setGridState(gridState === "adding" ? "removing" : "adding");
  };

  useEffect(() => {
    if (gridState === "adding" && !loading && !error) {
      setLoading(true);
      performQuery()
        .then(async (data) => {
          const hitsWithoutFirst = data.hits.hits.slice(1);
          const newFpisIds = hitsWithoutFirst.map(
            (hit) => hit._source.importcode
          );
          const opisIdsData = await getOpisIds(newFpisIds);

          const mappedOpisIds = new Set(initialData.map((item) => item.opisId));

          const newData = hitsWithoutFirst
            .map((hit) => {
              const opisIdObject = opisIdsData.find(
                (item) => item.storeId === hit._source.importcode
              );
              return {
                StoreID: hit._source.importcode,
                Brand: hit._source.brandname,
                Description: hit._source.displayname,
                Distance: `${hit.sort[0].toFixed(2)} mile(s)`,
                OpisID:
                  opisIdObject && opisIdObject.opisId
                    ? opisIdObject.opisId
                    : null,
              };
            })
            .filter(
              (hit) => hit.OpisID !== null && !mappedOpisIds.has(hit.OpisID)
            );
          setEsData(newData);
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        })
        .finally(() => setLoading(false));
    }
  }, [gridState, contextSize, contextDistance, initialData]);

  const addingColumns = [
    {
      accessorKey: "OpisID",
      header: "OpisID",
      size: 75,
      enableSorting: false,
    },
    {
      accessorKey: "StoreID",
      header: "FPISID",
      size: 150,
      enableSorting: false,
    },
    {
      accessorKey: "Description",
      header: "Description",
      size: 150,
      enableSorting: false,
    },
    {
      accessorKey: "Brand",
      header: "Brand",
      size: 100,
      enableSorting: false,
    },
    {
      accessorKey: "Distance",
      header: "Distance",
      size: 120,
      enableSorting: false,
    },
  ];

  const removingColumns = [
    {
      accessorKey: "OpisID",
      header: "OpisID",
      size: 75,
      enableSorting: false,
    },
    {
      accessorKey: "StoreID",
      header: "FPISID",
      size: 150,
      enableSorting: false,
    },
    {
      accessorKey: "Description",
      header: "Description",
      size: 150,
      enableSorting: false,
    },
    {
      accessorKey: "Brand",
      header: "Brand",
      size: 100,
      enableSorting: false,
    },
    {
      accessorKey: "Distance",
      header: "Distance",
      size: 75,
      enableSorting: false,
    },
    {
      accessorKey: "Price",
      header: "Price",
      size: 75,
      enableSorting: false,
    },
    {
      accessorKey: "EffDateTime",
      header: "Effective Date Time",
      size: 100,
      enableSorting: false,
    },
  ];

  const columns = gridState === "adding" ? addingColumns : removingColumns;

  const selectedRowState =
    gridState === "adding" ? selectedRowStateAdding : selectedRowStateRemoving;
  const setSelectedRowState =
    gridState === "adding"
      ? setSelectedRowStateAdding
      : setSelectedRowStateRemoving;
  const dataForTable =
    gridState === "adding" && !loading
      ? esData
      : initialData.map((item) => {
          const priceForSelectedGrade = item.productPrice.find(
            (price) =>
              price.productName === selectedPriceData?.priceData?.productName
          );

          return {
            OpisID: item ? item.opisId : "-",
            StoreID: item ? item.fpisStoreId : "-",
            Description: item ? item.importCode : "-",
            Brand: item ? item.brandName : "-",
            Price: priceForSelectedGrade ? priceForSelectedGrade.price : "-",
            EffDateTime: priceForSelectedGrade
              ? formatDateToString(priceForSelectedGrade.effectiveTime)
              : "-",
            Distance:
              item && item.distance != null
                ? `${item.distance.toFixed(2)} mile(s)`
                : "-",
          };
        });

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={dataForTable}
        enableRowSelection
        enablePagination={false}
        enableTopToolbar={false}
        enableBottomToolbar={false}
        onRowSelectionChange={setSelectedRowState}
        state={{ rowSelection: selectedRowState, columnSizing }}
        onColumnSizingChange={setColumnSizing}
        positionToolbarAlertBanner={"none"}
        enableColumnResizing={true}
        muiSelectCheckboxProps={{
          size: "small",
        }}
      />
      <CompetitorGridActionBar
        gridState={gridState}
        toggleGridState={toggleGridState}
        handleAction={gridState === "adding" ? handleAdd : handleRemove}
        actionLabel={gridState === "adding" ? "Cancel" : "Add"}
        selectedRows={selectedRowState}
        setDistance={setContextDistance}
        setSize={setContextSize}
      />
    </>
  );
};

export default CompetitorGrid;
