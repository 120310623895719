import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

const StrategyFormDataErrorList = () => {
  const {
    watch,
    formState: { errors },
  } = useFormContext();

  const competitors = watch("competitorOffsets");

  // Helpers / Utility
  const flattenObject = (obj: any): any => {
    let resultObj: any = {};

    for (const i in obj) {
      if (typeof obj[i] === "object" && !Array.isArray(obj[i])) {
        const tempObj = flattenObject(obj[i]);
        for (const j in tempObj) {
          resultObj[i + "." + j] = tempObj[j];
        }
      } else {
        resultObj[i] = obj[i];
      }
    }

    return resultObj;
  };

  const insert_at = (index, hostText, textToInsert) => {
    return [hostText.slice(0, index), textToInsert, hostText.slice(index)].join(
      ""
    );
  };

  const beautifyErrorIdentifiers = (identifier) => {
    // The identifiers generated by validations reference internal names.  Convert these to look more user friendly.
    let msg = identifier;

    msg = msg.replace("OpisAge.", "Max OPIS Age ");
    msg = msg.replace("LastDigitNine.", "Third Digit Nine ");
    msg = msg.replace("PriceFallUnderMinMax.", "Min/Max Price ");
    msg = msg.replace("MaxIncreaseDecrease.", "Max Inc/Dec ");
    msg = msg.replace("PriceMustChange.", "Price Must CH ");
    msg = msg.replace("CompetitorBoundary.", "Competitor Boundary ");

    msg = msg.replace("validationData.", " ");
    msg = msg.replace("minPrice", "- Min Price");
    msg = msg.replace("maxPrice", "- Max Price");
    msg = msg.replace("opisAge", "- Max Age");
    msg = msg.replace("maxDecrease", "- Max Decrease");
    msg = msg.replace("maxIncrease", "- Max Increase");
    msg = msg.replace("onFailAction", "- On Fail Action");

    // Indexes to opis ids.
    const rowIndex = msg.charAt(0);
    if (rowIndex <= "9" && rowIndex >= "0") {
      const opisId = competitors[rowIndex]?.competitorId;
      msg = msg.slice(1);
      msg = insert_at(0, msg, "Opis ID: " + opisId);
    }

    msg = msg.replace(".offset", " - Offset");
    msg = msg.replace(".lowerThreshold", " - Min Boundary");
    msg = msg.replace(".upperThreshold", " - Max Boundary");

    return msg;
  };

  return (
    <div>
      <ul className="strategyFormErrorList">
        {Object.entries(flattenObject(errors)).map(([key, value]) => {
          if (key.endsWith(".message")) {
            const keyStr = key.replace(".message", "");
            if (
              !keyStr.includes("competitorOffsets") &&
              !keyStr.includes("compBoundary")
            ) {
              // We have special handlers for competitorOffsets and compBoundary.  Ignore them here.
              return (
                <li key={key}>
                  Error: {`${beautifyErrorIdentifiers(keyStr)}: ${value}`}
                </li>
              );
            }
          }
        })}
        {/*Flattening didn't do enough to pull out the competitor offsets, so add them here.*/}
        {Object.entries(flattenObject(errors?.competitorOffsets)).map(
          ([key, value]) => {
            if (key.endsWith(".message")) {
              const keyStr = key.replace(".message", "");
              return (
                <li key={key}>
                  Error: Competitor {`${beautifyErrorIdentifiers(keyStr)}: ${value}`}
                </li>
              );
            }
          }
        )}

        {/*Same for comp boundaries.*/}
        {Object.entries(
          flattenObject(
            (errors as any)?.CompetitorBoundary?.validationData?.competitorBoundary
          )
        ).map(([key, value]) => {
          if (key.endsWith(".message")) {
            const keyStr = key.replace(".message", "");
            return (
              <li key={key}>
                Error: Competitor {`${beautifyErrorIdentifiers(keyStr)}: ${value}`}
              </li>
            );
          }
        })}
      </ul>
      {/*{JSON.stringify(competitors)}*/}
    </div>
  );
};

export default StrategyFormDataErrorList;
