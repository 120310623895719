import axios from 'axios';
import { useContext, useState } from "react";
import { constructHeader, formServiceUrl, getEnv } from "../util/apiUrlUtil";
import constants from "../util/constants/constants.json";
import { CompetitorPriceDataContext } from "../context/CompetitorPriceDataContext";
const useCreateStoreCompetitorMapping = () => {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { fetchData } = useContext(CompetitorPriceDataContext);

  const createMapping = async (seiId, opisIds) => {
    setLoading(true);
    setError(null);

    try {
      const headers = await constructHeader();
      const serviceUrl = await formServiceUrl(
        constants.urlConstants.priceGenPriceAdminMaster.name,
        constants.urlConstants.priceGenPriceAdminMaster.createStoreCompetitorMapping
      );

      const result = await axios.post(serviceUrl, [{ seiId, opisIds }], { headers });

      setResponse(result.data);
    } catch (err) {
      setError(err.response?.data || { message: 'An unknown error occurred' });
    } finally {
      setLoading(false);
      fetchData();
    }
  };

  return { response, loading, error, createMapping };
};

export default useCreateStoreCompetitorMapping;
