import React from "react";
import {getImagePath} from "../../../util/util";

interface PriceDisplayProps {
  onIncrement: (e) => void;
  onDecrement: (e) => void;
  disabled: boolean;
}

const AdjustPriceButtons: React.FC<PriceDisplayProps> = ({ onIncrement, onDecrement, disabled }) => {
  return (
      <div className="button-container">
        <button className="increment" onClick={onIncrement} aria-label="Increment" disabled={disabled}>
          <img src={getImagePath('up')} alt="Increment"/>
        </button>
        <button className="decrement" onClick={onDecrement} aria-label="Decrement" disabled={disabled}>
          <img src={getImagePath('down')} alt="Decrement"/>
        </button>
      </div>
  );
};

export default AdjustPriceButtons;
