import React from "react";

interface PriceDisplayProps {
  price: number;
  disabled: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const PriceDisplay: React.FC<PriceDisplayProps> = ({
  price,
  disabled,
  onClick,
}) => {
  const formattedPrice = formatValue(price);

  return (
    <button
      className={`value ${!disabled ? "clickable" : ""}`}
      onClick={onClick}
      // onWheel={disabled || onWheel ? undefined : onWheel} // Apply onWheel only when not disabled
      disabled={disabled}
      style={{
        background: "none",
        border: "none",
        padding: 0,
        fontSize: "inherit",
        cursor: !disabled ? "pointer" : "default",
        outline: "none",
      }}
    >
      {formattedPrice}
    </button>
  );
};

const formatValue = (val: number): string => {
  let [whole, decimals] = val.toFixed(3).split(".");
  decimals = `${decimals.substring(0, 2)}9`;
  return `$${whole}.${decimals}`;
};

export default PriceDisplay;
