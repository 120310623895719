import { SetPriceDataType, StatusType } from "../types/PriceReview.types";
import PriceDataModel from "../models/priceView/PriceData.model";

function roundToThreeDecimalPlaces(num: number) {
  return Number(num.toFixed(3));
}

class PriceDataUtilities {
  static changeProposedPriceStatus(
      setPriceData: SetPriceDataType,
      newStatus: StatusType,
      productId: string
  ) {
    setPriceData((prevPriceData) => {
      return prevPriceData.map((dataItem) => {
        const ret = new PriceDataModel(dataItem.priceData);
        ret.proposedPrice = dataItem.proposedPrice;
        if (dataItem.productId === productId && !dataItem.disabled) {
          if (dataItem.status === newStatus) ret.status = null;
          else ret.status = newStatus;
          return ret;
        }
        // If item not found, return it as is
        return dataItem;
      });
    });
  }

  static changeProposedPriceStatusForAll(
      setPriceData: SetPriceDataType,
      newStatus: StatusType
  ) {
    setPriceData((prevPriceData) => {
      // if all items match the status, set it to null.
      if (prevPriceData.every((dataItem) => dataItem.status === newStatus)) {
        return prevPriceData.map((dataItem) => {
          const ret = new PriceDataModel(dataItem.priceData);
          ret.status = null;
          return ret;
        });
      }
      // We use 'map' to iterate over all items without removing any.
      return prevPriceData.map((dataItem) => {
        // We clone the priceData object to maintain immutability.
        const ret = new PriceDataModel(dataItem.priceData);
        ret.proposedPrice = dataItem.proposedPrice;
        // Only adjust the status when the item's status is 'PENDING'
        if (!dataItem.disabled) {
          ret.status = newStatus;
        }
        // Always return the item, modified or not.
        return ret;
      });
    });
  }

  static adjustProposedPrice(
      setPriceData: SetPriceDataType,
      increment: boolean,
      productId: string
  ) {
    setPriceData((prevPriceData) => {
      return prevPriceData.map((dataItem) => {
        if (
            dataItem.productId === productId &&
            !dataItem.rejected &&
            !dataItem.disabled
        ) {
          const ret = new PriceDataModel(dataItem.priceData);
          const adjustmentAmount = increment ? 0.01 : -0.01;
          ret.proposedPrice = roundToThreeDecimalPlaces(
              dataItem.proposedPrice + adjustmentAmount
          );
          ret.status = "APPROVED";
          return ret;
        }
        return dataItem;
      });
    });
  }

  static adjustProposedPriceForAll(
      setPriceData: SetPriceDataType,
      increment: boolean
  ) {
    console.debug("adjustProposedPriceForAll", increment);
    setPriceData((prevPriceData) => {
      return prevPriceData.map((dataItem) => {
        const ret = new PriceDataModel(dataItem.priceData);
        const adjustmentAmount = increment ? 0.01 : -0.01;
        // only update if status is null and not disabled
        if (!dataItem.rejected && !dataItem.disabled) {
          ret.proposedPrice = roundToThreeDecimalPlaces(
              dataItem.proposedPrice + adjustmentAmount
          );
          ret.status = "APPROVED";
          return ret;
        } else {
          return dataItem;
        }
      });
    });
  }

  static updateProposedPrice(
      setPriceData: SetPriceDataType,
      newPrice: number,
      productId: string
  ) {
    setPriceData((prevPriceData) => {
      return prevPriceData.map((dataItem) => {
        const ret = new PriceDataModel(dataItem.priceData);
        if (
            dataItem.productId === productId &&
            newPrice !== dataItem.proposedPrice &&
            !dataItem.rejected &&
            !dataItem.disabled
        ) {
          ret.proposedPrice = newPrice;
          ret.status = "APPROVED";
          return ret;
        }
        return dataItem;
      });
    });
  }

  static setProposedPriceStatusFromProposedPrices(
      setPriceData: SetPriceDataType,
      proposedPrices: IProposedPrice[]
  ) {
    setPriceData((prevPriceData) => {
      return prevPriceData.map((dataItem) => {
        const proposedPrice = proposedPrices.find(
            (p) => p.productId === dataItem.productId
        );
        if (!proposedPrice) {
          return dataItem;
        }
        const priceData = dataItem.priceData;

        priceData.latestPriceGenPriceStatus = proposedPrice.status;
        priceData.latestPriceGenPrice = proposedPrice.proposedPrice;
        priceData.latestPriceGenPriceEffectiveDate =
            proposedPrice.effectiveDate;
        priceData.latestPriceGenId = proposedPrice.priceGenId;
        priceData.latestPriceGenModifiedBy = proposedPrice.modifiedBy;
        return new PriceDataModel(priceData);
      });
    });
  }

  static areAnyPricesFinalized(priceData: PriceDataModel[]) {
    return priceData.some((dataItem) => dataItem.status !== null);
  }
}

export default PriceDataUtilities;
