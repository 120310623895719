import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiClient from "../util/apiClient";
import { formServiceUrl } from "../util/apiUrlUtil";
import constants from "../util/constants/constants.json";
import {AxiosError} from "axios";

const saveStrategy = async (strategyJsonString: Object) => {
  console.debug("Save request json: " + JSON.stringify(strategyJsonString));
  const serviceUrl = await formServiceUrl(
    constants.urlConstants.priceGenPriceAdminMaster.name,
    constants.urlConstants.priceGenPriceAdminMaster.saveStrategy
  );
  const response = await apiClient.post(serviceUrl, strategyJsonString);
  return response?.data;
};

export const useSaveStrategy = () => {
  const queryClient = useQueryClient();

  return useMutation(saveStrategy, {
    onSuccess: (resultData) => {
      console.debug(
        "Successfully saved strategy.  Refetching " +
          constants.urlConstants.priceGenPriceAdminMaster.getStrategyQueryKey +
          "."
      );
      queryClient
        .invalidateQueries({
          queryKey: [
            constants.urlConstants.priceGenPriceAdminMaster.getStrategyQueryKey,
          ],
        })
        .then((r) => {
          console.debug("Refetch complete.");
        });
    },
    onError: (error : AxiosError) => {
      console.debug("Error saving strategy: " + JSON.stringify(error?.response?.data));
    },
  });
};
