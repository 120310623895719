/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from "react";
import DragAndDropList from "./DragAndDropList";
import "./ControlsAndValidations.css";
import {
  FormControl,
  FormControlLabel,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import MinMaxPriceValidationConfig from "./MinMaxPriceValidationConfig";
import MinMaxChangeValidationConfig from "./MinMaxChangeValidationConfig";
import LastDigitNineConfig from "./LastDigitNineConfig";
import PriceMustChangeConfig from "./PriceMustChangeConfig";
import {
  GetValidationParams,
  useGetValidation,
} from "../../../api/validationApi";
import InfoIcon from "@mui/icons-material/Info";
import { useFormContext, Controller } from "react-hook-form";
import OpisAgeValidationConfig from "./MaxOpisAgeValidationConfig";
import { CompetitorPriceDataContext } from "../../../context/CompetitorPriceDataContext";

const ControlsAndValidations = ({ storeId, productId, selectedRuleType }) => {
  const [selectedValidation, setSelectedValidation] = useState("LastDigitNine");
  const [loadedValidation, setLoadedValidation] = useState(null);
  const [initialValidation, setInitialValidation] = useState(null);
  const handleSelectedValidationChange = (newValidationSelection) => {
    setSelectedValidation(newValidationSelection);
    setShowAllValidations(false);
  };
  // const [schedule, setSchedule] = useState(new Map());
  // const [displayScheduler, setDisplayScheduler] = useState(false);
  const [showAllValidations, setShowAllValidations] = useState(false);

  const { control, reset } = useFormContext();

  const { initialData } = useContext(CompetitorPriceDataContext);

  const [hiddenValidationTypes, setHiddenValidationTypes] = useState([]);

  useEffect(() => {
    let hiddenTypes = ["CompetitorBoundary"];
    if(selectedRuleType === "RuleLinkToOwnProduct") {
      hiddenTypes.push("OpisAge");
    }
    setHiddenValidationTypes(hiddenTypes);
  }, [selectedRuleType]);

  const {
    data: validationDataLocal,
    isFetching: isValidationDataLocalLoading,
    refetch: refetchValidation,
  } = useGetValidation({
    storeId: storeId,
    productIdList: Array(1).fill(productId),
    profile: "US",
  } as GetValidationParams);

  function reloadFormDataDefaults() {
    console.debug("~reloadFormDataDefaults called~");
    let lastDigitNine = getValidatorByTypeValidationData(
      "LastDigitNine",
      validationDataLocal
    );
    let PriceFallUnderMinMax = getValidatorByTypeValidationData(
      "PriceFallUnderMinMax",
      validationDataLocal
    );
    let MaxIncreaseDecrease = getValidatorByTypeValidationData(
      "MaxIncreaseDecrease",
      validationDataLocal
    );
    let priceMustChange = getValidatorByTypeValidationData(
      "PriceMustChangeWhenSystemInitiated",
      validationDataLocal
    );
    let OpisAge = getValidatorByTypeValidationData(
      "OpisAge",
      validationDataLocal
    );
    let CompetitorBoundary = getValidatorByTypeValidationData(
      "CompetitorBoundary",
      validationDataLocal
    );

    let defaultValues = control._defaultValues;
    defaultValues.autoApprovalFlag = validationDataLocal?.autoApprove?.flag;
    defaultValues.validationId = validationDataLocal?.id;

    defaultValues.lastDigitNine = {
      validationName: lastDigitNine.validationName,
      validationType: lastDigitNine?.validationType,
      validationDescription: lastDigitNine?.validationDescription,
      priority: lastDigitNine?.priority,
      enabled: {
        flag: lastDigitNine?.enabled?.flag,
        schedule: lastDigitNine?.enabled?.schedule,
      },
      onFailAction: lastDigitNine?.onFailAction,
    };
    defaultValues.PriceFallUnderMinMax = {
      validationName: PriceFallUnderMinMax?.validationName,
      validationType: PriceFallUnderMinMax?.validationType,
      validationDescription: PriceFallUnderMinMax?.validationDescription,
      priority: PriceFallUnderMinMax?.priority,
      enabled: {
        flag: PriceFallUnderMinMax?.enabled?.flag,
        schedule: PriceFallUnderMinMax?.enabled?.schedule,
      },
      validationData: {
        minPrice: PriceFallUnderMinMax?.validationData?.minPrice,
        maxPrice: PriceFallUnderMinMax?.validationData?.maxPrice,
      },
      onFailAction: PriceFallUnderMinMax?.onFailAction,
    };
    defaultValues.MaxIncreaseDecrease = {
      validationName: MaxIncreaseDecrease?.validationName,
      validationType: MaxIncreaseDecrease?.validationType,
      validationDescription: MaxIncreaseDecrease?.validationDescription,
      priority: MaxIncreaseDecrease?.priority,
      enabled: {
        flag: MaxIncreaseDecrease?.enabled?.flag,
        schedule: MaxIncreaseDecrease?.enabled?.schedule,
      },
      validationData: {
        maxDecrease: MaxIncreaseDecrease?.validationData?.maxDecrease,
        maxIncrease: MaxIncreaseDecrease?.validationData?.maxIncrease,
      },
      onFailAction: MaxIncreaseDecrease?.onFailAction,
    };
    defaultValues.priceMustChange = {
      validationName: priceMustChange?.validationName,
      validationType: priceMustChange?.validationType,
      validationDescription: priceMustChange?.validationDescription,
      priority: priceMustChange?.priority,
      enabled: {
        flag: priceMustChange?.enabled?.flag,
        schedule: priceMustChange?.enabled?.schedule,
      },
      onFailAction: priceMustChange?.onFailAction,
    };

    defaultValues.OpisAge = {
      validationName: OpisAge?.validationName,
      validationType: OpisAge?.validationType,
      validationDescription: OpisAge?.validationDescription,
      priority: OpisAge?.priority,
      enabled: {
        flag: OpisAge?.enabled?.flag,
        schedule: OpisAge?.enabled?.schedule,
      },
      validationData: {
        opisAge: OpisAge?.validationData?.opisAge,
      },
      onFailAction: OpisAge?.onFailAction,
    };

    defaultValues.CompetitorBoundary = {
      validationName: CompetitorBoundary?.validationName,
      validationType: CompetitorBoundary?.validationType,
      validationDescription: CompetitorBoundary?.validationDescription,
      priority: CompetitorBoundary?.priority,
      enabled: {
        flag: CompetitorBoundary?.enabled?.flag,
        schedule: CompetitorBoundary?.enabled?.schedule,
      },
      validationData: {
        competitorBoundary:
          CompetitorBoundary?.validationData?.competitorBoundary.sort((a, b) =>
            a.opisId > b.opisId ? 1 : b.opisId > a.opisId ? -1 : 0
          ),
      },
      onFailAction: CompetitorBoundary?.onFailAction,
    };

    if (CompetitorBoundary?.validationData?.competitorBoundary?.length > 0) {
      for (const bound of CompetitorBoundary?.validationData
        ?.competitorBoundary) {
        if (bound?.lowerThreshold == null) {
          bound.lowerThreshold = "";
        }

        if (bound?.upperThreshold == null) {
          bound.upperThreshold = "";
        }
      }
    }

    // Every possible competitor needs an index associated in the data model or currently unassigned competitors won't be able to be edited.
    // Get competitors from the comp grid's data context and add on any that aren't already represented.
    initialData?.map((item) => {
      let exists = false;
      if (
        !!defaultValues?.CompetitorBoundary?.validationData?.competitorBoundary
      ) {
        for (const comp of defaultValues?.CompetitorBoundary?.validationData
          ?.competitorBoundary) {
          if (comp.opisId == item.opisId) {
            exists = true;
          }
        }
      }
      if (!exists) {
        defaultValues?.CompetitorBoundary?.validationData?.competitorBoundary.push(
          {
            opisId: item.opisId,
            lowerThreshold: null,
            upperThreshold: null,
          }
        );
      }
    });

    reset({ ...defaultValues });
  }

  // Setup some data when a new validation was just fetched.
  useEffect(() => {
    if (!isValidationDataLocalLoading) {
      console.log("validationDataLocal changed.");
      setLoadedValidation(validationDataLocal);
      setInitialValidation(validationDataLocal);
      reloadFormDataDefaults();
    }
  }, [isValidationDataLocalLoading]);

  // Refetch the validation if the store/product selection changes
  useEffect(() => {
    console.log("Store/Product changed.  Refetching validation.");
    if (!isValidationDataLocalLoading) {
      // Don't refetch if we're already doing it
      refetchValidation();
    }
  }, [storeId, productId]);

  // Helper function to find a specific validation from the list of validators.
  const getValidatorByTypeValidationData = (type, validationData) => {
    let index = null;

    for (let i = 0; i < validationData?.validationList?.length; i++) {
      if (type == validationData?.validationList[i]?.validationType) {
        index = i;
      }
    }

    if (index == null) {
      return null;
    }
    return validationData?.validationList[index];
  };

  // const getValidatorByType = (type) => {
  //   let i = getValidatorIndexByType(type);
  //   if (i == null) {
  //     return null;
  //   }
  //   return loadedValidation?.validationList[i];
  // };

  const getValidatorIndexByType = (type) => {
    for (let i = 0; i < loadedValidation?.validationList?.length; i++) {
      if (type == loadedValidation?.validationList[i]?.validationType) {
        return i;
      }
    }
    return null;
  };

  // Helper function to update a specific validation from the list
  const updateValidatorConfig = (newConfig) => {
    let temp = { ...loadedValidation };
    let i = getValidatorIndexByType(newConfig?.validationType);

    if (i != null) {
      temp.validationList[i] = newConfig;
    }

    setLoadedValidation(temp);
  };

  // Update priorities when the user drags and drops them.
  const onReorder = (newOrder) => {
    console.log(newOrder);
    let temp = { ...loadedValidation };

    for (let i = 0; i < newOrder.length; i++) {
      let c = getValidatorIndexByType(newOrder[i].validationType);
      if (c != null) {
        temp.validationList[c].priority = newOrder[i].priority;
      }
    }

    setLoadedValidation(temp);
  };

  return (
    <div className="validations-wrapper">
      <div className="validations">
        <FormControl component="fieldset" variant="standard">
          <div className="leftPanel">
            <DragAndDropList
              onValidationEdit={handleSelectedValidationChange}
              validationData={initialValidation}
              onReorder={onReorder}
              dragEnabled={false}
              showAllValidations={showAllValidations}
              selectedRuleType={selectedRuleType}
            />
            <Controller
              name="autoApprovalFlag"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  className="formControl"
                  control={<Switch {...field} checked={field.value} />}
                  label="Auto Approval"
                  disabled={selectedRuleType === "RuleLinkToOwnProduct"}
                />
              )}
            />
            {/*<IconButton*/}
            {/*  onClick={() => {*/}
            {/*    setDisplayScheduler(!displayScheduler);*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <AlarmIcon />*/}
            {/*</IconButton>*/}
            <FormControlLabel
              className="formControl"
              control={
                <Switch
                  checked={showAllValidations}
                  onChange={() => setShowAllValidations(!showAllValidations)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Show All"
            />
          </div>
          <div className={"rightPanel " + (showAllValidations ? "showAll" : "")}>
            <>
              {loadedValidation?.validationList?.filter( (validation) => !hiddenValidationTypes.includes(validation?.validationType))
                .map((validation) => {
                if (
                  showAllValidations ||
                  validation?.validationType === selectedValidation
                ) {
                  return (
                    <div className="individualValidationConfigBox" key={validation?.validationType}>
                      <Typography
                        variant="h1"
                        className="selectedValidationConfigTitle"
                      >
                        {validation?.validationName} configuration
                        {validation?.validationDescription && (
                          <Tooltip
                            title={validation?.validationDescription}
                            classes={{
                              popper: "validation-desc-tooltip",
                              tooltip: "validation-desc-tooltip",
                            }}
                          >
                            <IconButton>
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Typography>
                      <hr />
                      {validation.validationType === "LastDigitNine" && (
                        <LastDigitNineConfig
                          validatorData={validation}
                          onChange={updateValidatorConfig}
                        />
                      )}
                      {(validation.validationType === "OpisAge" && selectedRuleType != "RuleLinkToOwnProduct") && (
                        <OpisAgeValidationConfig
                          validatorData={validation}
                          onChange={updateValidatorConfig}
                        />
                      )}
                      {validation.validationType === "PriceFallUnderMinMax" && (
                        <MinMaxPriceValidationConfig
                          validatorData={validation}
                          onChange={updateValidatorConfig}
                        />
                      )}
                      {validation.validationType === "MaxIncreaseDecrease" && (
                        <MinMaxChangeValidationConfig
                          validatorData={validation}
                          onChange={updateValidatorConfig}
                        />
                      )}
                      {validation.validationType ===
                        "PriceMustChangeWhenSystemInitiated" && (
                        <PriceMustChangeConfig
                          validatorData={validation}
                          onChange={updateValidatorConfig}
                        />
                      )}
                    </div>
                  );
                }
              })}
            </>
          </div>
        </FormControl>
      </div>

      {/*{displayScheduler && (*/}
      {/*  <div className="validation-scheduler">*/}
      {/*    <Typography variant="h1" className="selectedValidationConfigTitle">*/}
      {/*      Schedule Auto Approval*/}
      {/*    </Typography>*/}
      {/*    <hr />*/}
      {/*    <WeeklyRecurringSchedulerControl*/}
      {/*      initialState={schedule}*/}
      {/*      onChange={setSchedule}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*)}*/}

      {/*<div>*/}
      {/*  <Typography variant="h1" className="selectedValidationConfigTitle">*/}
      {/*    {JSON.stringify(Array.from(schedule?.entries()))}*/}
      {/*  </Typography>*/}
      {/*</div>*/}
    </div>
  );
};

export default ControlsAndValidations;
