import React, { useEffect, useState } from "react";
import {
  Box,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const LastDigitNineConfig = ({ validatorData, onChange }) => {
  const {
    register,
    control,
    formState: { errors, isDirty, dirtyFields },
  } = useFormContext();

  return (
    <div>
      <Box component="form" className="validatorConfigPanel">
        <Controller
          name="lastDigitNine.enabled.flag"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              className="enableSwitch"
              control={<Switch {...field} checked={field.value} />}
              label="Enabled"
            />
          )}
        />
      </Box>
      <Typography variant="h1">Action to take on failure condition</Typography>
      <hr />
      <Controller
        name="lastDigitNine.onFailAction"
        control={control}
        render={({ field }) => (
          <Select {...field} label="Failure Action" error={!!(errors as any)?.lastDigitNine?.onFailAction}>
            <MenuItem value="EndWithNine">Set Third Digit to 9</MenuItem>
          </Select>
        )}
      />
    </div>
  );
};

export default LastDigitNineConfig;
