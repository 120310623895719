import React, { useContext, useState } from "react";
import { PriceDataContext } from "../../context/PriceDataContext";
import PriceDataModel from "../../models/priceView/PriceData.model";
import "./ActionButtonTd.css";
import { getImagePath } from "../../util/util";

interface ActionTdProps {
  dataItem: PriceDataModel;
  mode: "approve" | "deny";
}

const modeMap = {
  deny: {
    action: "REJECTED",
    altText: "Deny",
    whenPending: "deny",
    whenHovered: "denyRed",
    whenActive: {
      iconName: "denyRed",
      style: {
        backgroundColor: "#FFDFDF",
      },
    },
    whenDisabled: "denyDisabled",
    className: "status-rejected",
  },
  approve: {
    action: "APPROVED",
    altText: "Approve",
    whenPending: "checkmark",
    whenHovered: "checkmarkGreen",
    whenActive: {
      iconName: "checkmarkGreen",
      style: {
        backgroundColor: "#CAFFCA",
      },
    },
    whenDisabled: "checkmarkDisabled",
    className: "status-approved",
  },
};

function getImageNameBasedOnStatus(
    dataItem: PriceDataModel,
    mode: keyof typeof modeMap,
    isHovered: boolean,
    isDisabled: boolean,
    isActive: boolean
) {
  const {priceData: { latestPriceGenPriceStatus } } = dataItem;
  const modeConfig = modeMap[mode];

  // If the current button is disabled, the presentation depends on whether the data item is in a pending state
  if (isDisabled) {
    return modeConfig.whenDisabled;
  }

  // Handle cases where the item is currently "active" or being "hovered over"
  if (isActive) {
    return modeConfig.whenActive.iconName;
  } else if (isHovered) {
    return modeConfig.whenHovered;
  }

  // If the current button's action corresponds to the item's latestPriceGenPriceStatus
  if (latestPriceGenPriceStatus === modeConfig.action) {
    return modeConfig.whenActive.iconName;
  }

  // If the latestPriceGenPriceStatus is 'PENDING' or doesn't match either 'APPROVED' or 'REJECTED',
  // we should assume it is a transitional state, so we revert to showing the pending icon.
  return modeConfig.whenPending;
}



export const ActionButtonTd: React.FC<ActionTdProps> = ({ dataItem, mode }) => {
  const priceDataCtx = useContext(PriceDataContext);
  const { changeProposedPriceStatus } = priceDataCtx;

  const [isHovered, setIsHovered] = useState(false);

  const modeConfig = modeMap[mode];

  const isDisabled = dataItem.priceData.latestPriceGenPriceStatus !== "PENDING";
  const isActive = !isDisabled && dataItem.status === modeConfig.action;

  const imageName = getImageNameBasedOnStatus(
    dataItem,
    mode,
    isHovered,
    isDisabled,
    isActive
  );

  function onClick(e: React.MouseEvent) {
    e.stopPropagation();
    if (!isDisabled) {
      changeProposedPriceStatus(modeConfig.action, dataItem.productId);
    }
  }

  return (
    <td
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={`action-td bordered-cell ${
        isActive ? modeConfig.className : ""
      } ${isDisabled ? "disabled" : ""}`}
      style={isActive ? modeConfig.whenActive.style : {}}
      onClick={onClick}
    >
      <button className={"action-btn"} onClick={onClick} disabled={isDisabled}>
        <img src={getImagePath(imageName)} alt={modeConfig.altText} />
      </button>
    </td>
  );
};
