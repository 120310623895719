import React, { useEffect, useState } from "react";
import {
  Box,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const MaxOpisAgeValidationConfig = ({ validatorData, onChange }) => {
  const {
    register,
    control,
    formState: { errors, isDirty, dirtyFields },
  } = useFormContext();

  return (
    <div>
      <Box component="form" className="validatorConfigPanel">
        <Controller
          name="OpisAge.enabled.flag"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              className="enableSwitch"
              control={<Switch {...field} checked={field.value} />}
              label="Enabled"
            />
          )}
        />
        <Controller
          name="OpisAge.validationData.opisAge"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              // onChange={(e) => field.onChange(checkInt(e.target.value))}
              onKeyDown={(event) => {
                if (event?.key === "-" || event?.key === "+") {
                  event.preventDefault();
                }
              }}
              error={!!(errors as any)?.OpisAge?.validationData?.opisAge}
              label="Max Age"
              variant="standard"
              type="number"
              inputProps={{
                step: 1,
                min: 0,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">hours</InputAdornment>
                ),
              }}
            />
          )}
        />
      </Box>
      <Typography variant="h1">Action to take on failure condition</Typography>
      <hr />
      <Controller
        name="OpisAge.onFailAction"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            label="Failure Action"
            error={!!(errors as any)?.OpisAge?.onFailAction}
          >
            <MenuItem value="RemoveOpisProductPrice">
              Ignore Competitor
            </MenuItem>
          </Select>
        )}
      />
    </div>
  );
};

export default MaxOpisAgeValidationConfig;
