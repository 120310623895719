import { useQuery } from "@tanstack/react-query";
import apiClient from "../util/apiClient";
import { formServiceUrl } from "../util/apiUrlUtil";
import constants from "../util/constants/constants.json";

type Validator = {
  validationName: string;
  validationType: string;
  validationDescription: string;
  priority: number;
  enabled: ScheduleToggleConfig;
  validationData: object;
  onfailAction: string;
};

type Validation = {
  id: object;
  storeId: string;
  productId: string;
  autoApprove: ScheduleToggleConfig;
  validationList: Array<Validator>;
};

export type ScheduleToggleConfig = {
  flag: boolean;
  schedule: object;
};

export type GetValidationParams = {
  storeId: string;
  productIdList: string[];
  profile: string;
};

const fetchValidation = async (
  params: GetValidationParams
): Promise<Validation> => {
  console.debug("FETCH VALIDATION: params: " + JSON.stringify(params));

  const serviceUrl = await formServiceUrl(
    constants.urlConstants.priceGenPriceAdminMaster.name, //"pricegen-controls-validations-persist",
    constants.urlConstants.priceGenPriceAdminMaster.fetchValidationWithDefault
  );

  const { data } = await apiClient.post(serviceUrl, { ...params });

  console.debug("Fetch Validation RESULT: " + JSON.stringify(data[0]));
  return data[0]; // API allows multiple products sent for multiple results, but we're only using one at a time.  Just return the first item from the result array.
};

// const getDummyValidation = async (
//   params: GetValidationParams
// ): Promise<Validation> => {
//   return {
//     _id: {
//       $oid: "65f179f9707ff6983eb3f21f",
//     },
//     storeId: params.storeId,
//     productId: params.productIdList[0],
//     autoApprove: true,
//     validationList: [
//       {
//         validationName: "Last Digit 9",
//         validationType: "LastDigitNine",
//         validationDescription:
//           "If the price doesn't have 3-digits after the decimal, ending in 9, make it so.",
//         priority: 0,
//         enabled: true,
//         validationData: {},
//         onfailAction: "EndwithNine",
//       },
//       {
//         validationName: "Min/Max Price",
//         validationType: "PriceFallUnderMinMax",
//         validationDescription:
//           "Check if the price comes under the min and max value",
//         priority: 1,
//         enabled: true,
//         validationData: {
//           minPrice: "2.445",
//           maxPrice: "3.459",
//         },
//         onfailAction: "RequireApproval",
//       },
//       {
//         validationName: "Max Increase/Decrease",
//         validationType: "CentFallUnderMinMax",
//         validationDescription:
//           "Check if the cent comes under the min and max value",
//         priority: 2,
//         enabled: true,
//         validationData: {
//           minCent: "0.5",
//           maxCent: "1",
//         },
//         onfailAction: "RequireApproval",
//       },
//       {
//         validationName: "Price Must Change",
//         validationType: "PriceMustChangeWhenSystemInitiated",
//         validationDescription:
//           "Check that the new price calculation has changed from the last proposed price.",
//         priority: 3,
//         enabled: true,
//         validationData: {},
//         onfailAction: "BlockPriceGenCycle",
//       },
//     ],
//   };
// };

export const useGetValidation = (params: GetValidationParams) => {
  const QUERY_KEY = [
    constants.urlConstants.priceGenPriceAdminMaster.getValidationQueryKey,
  ];

  return useQuery<Validation, Error>({
    queryKey: QUERY_KEY,
    queryFn: () => fetchValidation(params), // getDummyValidation(params),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
};
