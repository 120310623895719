import React, { useContext } from "react";
import { CompetitorPriceDataContext } from "../../../context/CompetitorPriceDataContext";
import "./CompetitorGridActionBar.css";
import { MRT_RowSelectionState } from "material-react-table";

interface CompetitorGridActionBarProps {
  gridState: "adding" | "removing";
  toggleGridState: () => void;
  handleAction: () => void;
  actionLabel: string;
  selectedRows: MRT_RowSelectionState;
  setDistance: (distance: string) => void;
  setSize: (size: number) => void;
}

const CompetitorGridActionBar: React.FC<CompetitorGridActionBarProps> = ({
  gridState,
  toggleGridState,
  selectedRows,
  handleAction,
  actionLabel,
  setDistance,
  setSize,
}) => {
  const { size } = useContext(CompetitorPriceDataContext);

  const handleSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSize = e.target.valueAsNumber;
    if (!isNaN(newSize) && newSize >= 1) {
      setSize(newSize);
    } else if (e.target.value === "") {
      setSize(1);
    }
  };

  return (
    <div className="action-bar-container">
      <div className="selectors-container">
        {gridState === "adding" && (
          <>
            <select onChange={(e) => setDistance(e.target.value)}>
              <option value="5mi">5 miles</option>
              <option value="10mi">10 miles</option>
              <option value="25mi">25 miles</option>
              <option value="50mi">50 miles</option>
            </select>
            <input
              className="size-input"
              type="number"
              min="1"
              value={size}
              onChange={handleSizeChange}
            />
          </>
        )}
      </div>
      <div className="buttons-container">
        <button
          onClick={handleAction}
          disabled={Object.keys(selectedRows).length === 0}
          className = "CG-Action-Button"
        >
          {gridState === "adding" ? "Add" : "Remove"}
        </button>
        <button
          onClick={toggleGridState}
          className = "CG-Grid-Button"
        >
          {gridState === "adding" ? "Cancel" : "Add"}
        </button>
      </div>
    </div>
  );
};

export default CompetitorGridActionBar;
