import { z } from "zod";

const zodSchema = z
  .object({
    id: z.string().optional().nullable(),
    validationId: z.string().optional().nullable(),
    autoApprovalFlag: z.boolean(),
    ruleType: z.string(),
    mapping: z
      .object({
        storeId: z.string(),
        productId: z.string(),
      })
      .optional(),
    competitorOffsets: z
      .array(
        z.object({
          competitorId: z.string(),
          offset: z.coerce
            .string()
            .transform((value) => (value === "" ? null : value))
            .nullable()
            .refine((value) => value === null || !isNaN(Number(value)), {
              message: "Invalid number",
            })
            .transform((value) => (value === null ? null : Number(value)))
            .optional(),
          isSelected: z.boolean().optional(),
        })
      ),
    linkedProduct: z.string().nullable(),
    linkedProductSpread: z.coerce.number().nullable(),
    lastDigitNine: z.object({
      validationName: z.string(),
      validationType: z.string(),
      validationDescription: z.string(),
      priority: z.number(),
      enabled: z.object({
        flag: z.boolean(),
        schedule: z.object({}),
      }),
      onFailAction: z.string(),
    }),
    CompetitorBoundary: z.object({
      validationName: z.string(),
      validationType: z.string(),
      validationDescription: z.string(),
      priority: z.number(),
      validationData: z.object({
        competitorBoundary: z.array(
          z
            .object({
              opisId: z.string(),
              // Zod is using Number() internally to coerce to check numbers.  MUI treats empty text fields as empty strings, which get converted to 0 by Number().  This is a workaround to allow both null and 0.
              lowerThreshold: z.coerce
                .string()
                .transform((value) => (value === "" ? null : value))
                .nullable()
                .refine((value) => value === null || !isNaN(Number(value)), {
                  message: "Invalid number",
                })
                .transform((value) => (value === null ? null : Number(value)))
                .optional(),
              upperThreshold: z.coerce
                .string()
                .transform((value) => (value === "" ? null : value))
                .nullable()
                .refine((value) => value === null || !isNaN(Number(value)), {
                  message: "Invalid number",
                })
                .transform((value) => (value === null ? null : Number(value)))
                .optional(),
            })
            .refine(
              (data) =>
                data.lowerThreshold <= data.upperThreshold ||
              data.upperThreshold == null || data.lowerThreshold == null,
              {
                message: "Min boundary must not exceed max boundary.",
                path: ["lowerThreshold"],
              }
            )
        ),
      }),
      enabled: z.object({
        flag: z.boolean(),
        schedule: z.object({}).optional(),
      }),
      onFailAction: z.string().optional(),
    }),
    OpisAge: z.object({
      validationName: z.string(),
      validationType: z.string(),
      validationDescription: z.string(),
      priority: z.number(),
      validationData: z.object({
        opisAge: z.coerce.number().nonnegative(),
      }),
      enabled: z.object({
        flag: z.boolean(),
        schedule: z.object({}).optional(),
      }),
      onFailAction: z.string(),
    }),
    PriceFallUnderMinMax: z
      .object({
        enabled: z.object({
          flag: z.boolean(),
          schedule: z.object({}),
        }),
        validationName: z.string(),
        validationType: z.string(),
        validationDescription: z.string(),
        priority: z.number(),
        validationData: z.object({
          minPrice: z.coerce.number().nonnegative(),
          maxPrice: z.coerce.number().nonnegative(),
        }),
        onFailAction: z.string().optional(),
      })
      .refine(
        (data) => data.validationData.minPrice <= data.validationData.maxPrice,
        {
          message: "Min price must not exceed max price.",
          path: ["validationData.minPrice"],
        }
      ),
    MaxIncreaseDecrease: z.object({
      enabled: z.object({
        flag: z.boolean(),
        schedule: z.object({}),
      }),
      validationName: z.string(),
      validationType: z.string(),
      validationDescription: z.string(),
      priority: z.number(),
      validationData: z.object({
        maxDecrease: z.coerce.number().nonnegative(),
        maxIncrease: z.coerce.number().nonnegative(),
      }),
      onFailAction: z.string().optional(),
    }),
    priceMustChange: z.object({
      enabled: z.object({
        flag: z.boolean(),
        schedule: z.object({}),
      }),
      validationName: z.string(),
      validationType: z.string(),
      validationDescription: z.string(),
      priority: z.number(),
      onFailAction: z.string().optional(),
    }),
    competitorSelections: z.array(z.string()).optional(),
  })
  .refine(
    (data) => {
      return data.ruleType === "RuleLinkToOwnProduct" || (
        data.competitorOffsets.some((comp) => comp.isSelected) &&
        !data.competitorOffsets.some(
          (comp) => comp.isSelected && !Number.isFinite(comp.offset as number)
        )
      );
    },
    {
      message: "Must select at least one competitor and provide offsets for selected competitors.",
      path: ["selections"],
    }
  )
  .refine(
    (data) => {
      return !(data.ruleType == "RuleLinkToOwnProduct" && !data.linkedProduct);
    },
    {
      message: "If rule is set to linked, linked product is required.",
      path: ["linkedProduct"],
    }
  );

export default zodSchema;
