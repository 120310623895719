import React, { useContext } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
// import LinkableProductSelection from "./LinkableProductSelection";
// import LinkedProductSpread from "./LinkedProductSpread";
import { Controller, useFormContext } from "react-hook-form";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import { PriceDataContext } from "../../../context/PriceDataContext";

function RuleLinkToOwnProductSettings(props: {
  rule: string;
  // productValue: string;
  // onLinkedProductChange: (event: SelectChangeEvent) => void;
  // spreadValue: number;
  // onProductSpreadChange: (event) => void;
  strategy;
}) {
  const {
    register,
    control,
    formState: { errors, isDirty, dirtyFields},
  } = useFormContext();

  const priceDataCtx = useContext(PriceDataContext);

  const getProductMenuItemsFromContext = () => {
    const menuItems = [];

    priceDataCtx.priceData.forEach((priceData) => {
      if (priceDataCtx.selectedPriceData?.productId != priceData?.productId) {
        // Don't allow self-linkage.
        menuItems.push(
          <MenuItem key={priceData?.productId} value={priceData?.productId}>
            {priceData.displayName}
          </MenuItem>
        );
      }
    });

    return menuItems;
  };

  const getLinkedProductOriginal = () => {
    if (props?.strategy?.rules?.length > 0) {
      return props.strategy?.rules[0]?.linkedProduct;
    }
    return null;
  };
  const getSpreadOriginal = () => {
    if (props?.strategy?.rules?.length > 0) {
      return props.strategy?.rules[0]?.spread / 100;
    }
    return null;
  };
  return (
    <>
      {props.rule === "RuleLinkToOwnProduct" && (
        <>
          <div className="rule-container">
            <div className={"div-wrapper"}>
              <div className={"section-label"}>Link Product</div>
            </div>
            <div className={"rule-dropdown-container"}>
              <FormControl fullWidth>
                {/*<Select*/}
                {/*  labelId="demo-simple-select-label"*/}
                {/*  id="demo-simple-select"*/}
                {/*  // value={value}*/}
                {/*  displayEmpty*/}
                {/*  // onChange={onChange}*/}
                {/*  // error={hasError}*/}
                {/*  {...register("rules.0.linkedProduct")}*/}
                {/*  // defaultValue={getLinkedProductOriginal()}*/}
                {/*>*/}
                {/*  {getProductMenuItemsFromContext()}*/}
                {/*</Select>*/}
                {/*{hasError && (*/}
                {/*  <Typography color="red">Required field!</Typography>*/}
                {/*)}*/}

                <Controller
                  name="linkedProduct"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // value={value}
                      displayEmpty
                      // onChange={onChange}
                      error={!!errors.linkedProduct}
                      //{...register("rules.0.linkedProduct")}
                      // defaultValue={getLinkedProductOriginal()}
                    >
                      {getProductMenuItemsFromContext()}
                    </Select>
                  )}
                />
              </FormControl>
            </div>
          </div>
          {/*<LinkableProductSelection*/}
          {/*  value={props.productValue}*/}
          {/*  onChange={props.onLinkedProductChange}*/}
          {/*  originalValue={getLinkedProductOriginal()}*/}
          {/*  //{...register("linkedProduct")}*/}
          {/*/>*/}
          {/*<LinkedProductSpread*/}
          {/*  // value={props.spreadValue}*/}
          {/*  // onChange={props.onProductSpreadChange}*/}
          {/*  originalValue={getSpreadOriginal()}*/}
          {/*  {...register("linkedProductSpread")}*/}
          {/*/>*/}
          <div className="rule-container">
            <div className={"div-wrapper"}>
              <div className={"section-label"}>Spread</div>
            </div>
            <div
              className={
                !!dirtyFields.linkedProductSpread
                  ? "rule-dropdown-container linked-spread hasPendingChange"
                  : "rule-dropdown-container linked-spread"
              }
            >
              <FormControl fullWidth>
                <Controller
                  name="linkedProductSpread"
                  control={control}
                  rules={{ min: 0 }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      error={!!errors.linkedProductSpread}
                      // value={props.spreadValue}
                      // onChange={props.onProductSpreadChange}
                      inputProps={{ step: "0.01" }}
                      //{...register("rules.0.linkedProductSpread", { min: 0 })}
                      // defaultValue={getSpreadOriginal()}
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default RuleLinkToOwnProductSettings;
